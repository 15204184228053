import {
  ADD_TEACHER_OBJECTIVES_API_URL,
  ADD_TRACKINGS_LIST_API_URL,
  GET_GOAL_PERCENTAGE_API_URL,
  GET_TEACHER_OBJECTIVES_LIST_API_URL,
  GET_TRACKINGS_LIST_API_URL,
  STUDENTS_GROUPS_LIST_API_URL,
  TEACHERS_LIST_API_URL,
  GROUP_PROGRESS_EXTENDED_DATA_API_URL,
  TRACKING_GOALS_OR_OBJECTIVES_LIST_API_URL,
  DELETE_TEACHER_OBJECTIVES_API_URL,
  INDEPENDENT_GOALS_API_URL,
  CHANGE_TEACHER_STATUS_API_URL,
  UPDATE_TEACHER_DATA_API_URL,
  GET_STUDENT_PROGRESS_REPORT_API_URL,
} from '../../consts';
import {
  getStudentsGroupsDataRequestError,
  getStudentsGroupsDataRequestSuccess,
  getStudentsGroupsDataRequestStart,
  getTrackingGoalsDataRequestError,
  getTrackingGoalsDataRequestSuccess,
  getTrackingGoalsDataRequestStart,
  getTeachersListDataRequestError,
  getTeachersListDataRequestSuccess,
  getTeachersListDataRequestStart,
  getTrackingObjectivesDataRequestError,
  getTrackingObjectivesDataRequestStart,
  getTrackingObjectivesDataRequestSuccess,
  getTrackingsDataRequestError,
  getTrackingsDataRequestSuccess,
  getTrackingsDataRequestStart,
  addTrackingsDataRequestStart,
  addTrackingsDataRequestError,
  addTrackingsDataRequestSuccess,
  getTeacherObjectivesDataRequestStart,
  getTeacherObjectivesDataRequestSuccess,
  getTeacherObjectivesDataRequestError,
  addTeacherObjectivesDataRequestStart,
  addTeacherObjectivesDataRequestSuccess,
  addTeacherObjectivesDataRequestError,
  getGoalPercentageDataRequestStart,
  getGoalPercentageDataRequestError,
  getGoalPercentageDataRequestSuccess,
  getGroupProgressExtendedDataRequestSuccess,
  getGroupProgressExtendedDataRequestError,
  getGroupProgressExtendedDataRequestStart,
  deleteTeacherObjectivesDataRequestSuccess,
  deleteTeacherObjectivesDataRequestError,
  deleteTeacherObjectivesDataRequestStart,
  changeTeacherStatusRequestSuccess,
} from '../slices/studentTracker';
import { errorMessage, successMessage } from '../utils';
import { parseStudentGroupsAll } from '../utils/dataParsers';
import axiosExt from '../utils/request';

export const actionGetTeachersList = () => dispatch => {
  dispatch(getTeachersListDataRequestStart());
  axiosExt({
    method: 'POST',
    data: {
      teacherID: 0,
      subjectID: 0,
      studentID: 0,
      status: 0,
    },
    url: TEACHERS_LIST_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      if (Result) {
        dispatch(getTeachersListDataRequestSuccess(Result));
      } else {
        dispatch(getTeachersListDataRequestError(['Problem with Teachers List loading']));
      }
    })
    .catch(error => {
      dispatch(getTeachersListDataRequestError([`GET Teacher List error: ${error.message}`]));
    });
};

export const actionGetTeacherStudentsGroups = requestData => dispatch => {
  dispatch(getStudentsGroupsDataRequestStart());
  axiosExt({
    method: 'POST',
    data: requestData,
    url: STUDENTS_GROUPS_LIST_API_URL,
  })
    .then(res => {
      dispatch(getStudentsGroupsDataRequestSuccess(parseStudentGroupsAll(res)));
    })
    .catch(error => dispatch(getStudentsGroupsDataRequestError([`GET Student Groups error: ${error.message}`])));
};

export const actionGetTrackingGoals = requestData => dispatch => {
  dispatch(getTrackingGoalsDataRequestStart());
  axiosExt({
    method: 'POST',
    data: requestData,
    url: TRACKING_GOALS_OR_OBJECTIVES_LIST_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;

      if (Result) {
        const arrayUniqueByKey = [...new Map(Result.map(item => [item.GoalID, item])).values()];
        dispatch(getTrackingGoalsDataRequestSuccess({ data: arrayUniqueByKey, meta: requestData }));
      } else {
        dispatch(getTrackingGoalsDataRequestError(['Problem with Goals loading']));
      }
    })
    .catch(error => {
      dispatch(getTrackingGoalsDataRequestError([`GET Goals error: ${error.message}`]));
    });
};

export const actionGetTrackingObjectives = requestData => dispatch => {
  dispatch(getTrackingObjectivesDataRequestStart(requestData));
  axiosExt({
    method: 'POST',
    data: requestData,
    url: INDEPENDENT_GOALS_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      if (Result) {
        const arrayUniqueByKey = [...new Map(Result.map(item => [item.ObjectiveID, item])).values()];
        dispatch(getTrackingObjectivesDataRequestSuccess({ data: arrayUniqueByKey, meta: requestData }));
      } else {
        dispatch(getTrackingObjectivesDataRequestError(['Problem with Objectives loading']));
      }
    })
    .catch(error => dispatch(getTrackingObjectivesDataRequestError([`GET Objectives error: ${error.message}`])));
};
export const actionGetTrackings = requestData => dispatch => {
  dispatch(getTrackingsDataRequestStart());
  axiosExt({
    method: 'POST',
    data: requestData,
    url: GET_TRACKINGS_LIST_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      if (Result) {
        dispatch(getTrackingsDataRequestSuccess(Result));
      } else {
        dispatch(getTrackingsDataRequestError(['Problem with Trackings loading']));
      }
    })
    .catch(error => dispatch(getTrackingsDataRequestError([`GET Trackings error: ${error.message}`])));
};

export const actionAddTrackings = (requestData, callback) => dispatch => {
  dispatch(addTrackingsDataRequestStart());
  axiosExt({
    method: 'POST',
    data: requestData,
    url: ADD_TRACKINGS_LIST_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      callback();
      if (Result) {
        dispatch(addTrackingsDataRequestSuccess({ data: Result, meta: requestData }));
      } else {
        dispatch(addTrackingsDataRequestError(['Problem with Add Tracking']));
      }
    })
    .catch(error => {
      callback();
      dispatch(addTrackingsDataRequestError([`ADD Tracking error: ${error.message}`]));
    });
};

export const actionGetGroupProgressExtendedData = requestData => dispatch => {
  dispatch(getGroupProgressExtendedDataRequestStart());
  axiosExt({
    method: 'POST',
    data: requestData,
    url: GROUP_PROGRESS_EXTENDED_DATA_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      if (Result) {
        dispatch(getGroupProgressExtendedDataRequestSuccess({ data: Result, meta: requestData }));
      } else {
        dispatch(getGroupProgressExtendedDataRequestError(['Problem with load group progress']));
      }
    })
    .catch(error => dispatch(getGroupProgressExtendedDataRequestError([`Get group progress error: ${error.message}`])));
};

export const actionGetTeacherObjectives = requestData => dispatch => {
  dispatch(getTeacherObjectivesDataRequestStart());
  return new Promise(resolve => {
    axiosExt({
      method: 'POST',
      data: requestData,
      url: GET_TEACHER_OBJECTIVES_LIST_API_URL,
    })
      .then(res => {
        const { data: { Result } = {} } = res;
        if (Result) {
          const arrayUniqueByKey = [...new Map(Result.map(item => [item.ObjectiveID, item])).values()];
          dispatch(getTeacherObjectivesDataRequestSuccess({ data: arrayUniqueByKey, meta: requestData }));
        } else {
          dispatch(getTeacherObjectivesDataRequestError(['Problem with Teacher Objectives loading']));
        }
      })
      .catch(error =>
        dispatch(getTeacherObjectivesDataRequestError([`GET Teacher Objectives error: ${error.message}`])),
      )
      .finally(() => {
        resolve('success');
      });
  });
};

export const actionAddTeacherObjectives = requestData => dispatch => {
  dispatch(addTeacherObjectivesDataRequestStart());
  return new Promise((resolve, reject) => {
    axiosExt({
      method: 'POST',
      data: requestData,
      url: ADD_TEACHER_OBJECTIVES_API_URL,
      hideGlobalErrors: true,
    })
      .then(res => {
        const { data: { Result } = {} } = res;
        if (Result) {
          dispatch(addTeacherObjectivesDataRequestSuccess({ data: Result, meta: requestData }));
        } else {
          dispatch(addTeacherObjectivesDataRequestError(['Problem with add Teacher Objectives']));
        }
      })
      .catch(error => {
        reject(`Problem with add objectives to teacher: ${error?.message}`);
        return dispatch(
          addTeacherObjectivesDataRequestError([
            `Add Teacher Objectives error: ${error.message}. Some data may not have been changed.`,
          ]),
        );
      })
      .finally(() => {
        resolve('success');
      });
  });
};

export const actionDeleteTeacherObjectives = requestData => dispatch => {
  dispatch(deleteTeacherObjectivesDataRequestStart());
  return new Promise((resolve, reject) => {
    axiosExt({
      method: 'DELETE',
      data: requestData,
      url: DELETE_TEACHER_OBJECTIVES_API_URL,
      hideGlobalErrors: true,
    })
      .then(res => {
        dispatch(deleteTeacherObjectivesDataRequestSuccess());
        resolve('success');
      })
      .catch(error => {
        reject(`Problem with remove objectives from teacher: ${error?.message}`);
        return dispatch(
          deleteTeacherObjectivesDataRequestError([
            `Delete Teacher Objectives error: ${error.message}. Some data may not have been changed.`,
          ]),
        );
      })
      .finally(() => {
        resolve('success');
      });
  });
};

export const actionGetGoalPercentage = requestData => dispatch => {
  dispatch(getGoalPercentageDataRequestStart());
  axiosExt({
    method: 'POST',
    data: requestData,
    url: GET_GOAL_PERCENTAGE_API_URL,
  })
    .then(res => {
      const { data: { Result } = {} } = res;
      if (Result) {
        dispatch(getGoalPercentageDataRequestSuccess({ data: Result, meta: requestData }));
      } else {
        dispatch(getGoalPercentageDataRequestError(['Problem with Goal Percentage loading']));
      }
    })
    .catch(error => dispatch(getGoalPercentageDataRequestError([`GET Goal Percentage error: ${error.message}`])));
};

export const actionChangeTeacherStatus = requestData => dispatch => {
  const { status, teacherID } = requestData;
  const convertedStatus = status === 1 ? 'S' : 'N';
  return new Promise((resolve, reject) => {
    axiosExt({
      method: 'POST',
      data: { teacherID, status: convertedStatus },
      url: CHANGE_TEACHER_STATUS_API_URL,
    })
      .then(() => {
        resolve('success');
        dispatch(changeTeacherStatusRequestSuccess({ meta: requestData }));
      })
      .catch(error => {
        reject('error');
        errorMessage(`Error: ${error}`);
      });
  });
};

export const actionUpdateTeacherData = requestData => dispatch => {
  return new Promise((resolve, reject) => {
    axiosExt({
      method: 'POST',
      data: requestData,
      url: UPDATE_TEACHER_DATA_API_URL,
    })
      .then(res => {
        const { data: { Result: { TeacherID } } = {} } = res;
        successMessage('Data saved!');
        resolve(TeacherID);
      })
      .catch(error => {
        reject(`Problem with remove objectives from teacher: ${error?.message}`);
        errorMessage(`Error: ${error}`);
      });
  });
};

export const actionGetStudentProgressReport = requestData => dispatch => {
  return new Promise(resolve => {
    axiosExt({
      method: 'POST',
      data: requestData,
      url: GET_STUDENT_PROGRESS_REPORT_API_URL,
    }).then(res => {
      const { data: { Result } = {} } = res;
      resolve(Result);
    });
  });
};
