import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isGroupReportSaveRequestLoading: false,
  isFormReportSaveRequestLoading: false,
  isFormReportGetRequestLoading: false,
  isStudentReportSaveRequestLoading: false,
  isGroupReportGetRequestLoading: false,
  isStudentReportGetRequestLoading: false,
  groupsReportsDataHashList: {},
  studentReportsDataHashList: {},
  formReportsDataHashList: {},
};

export const createCombinedFormReportID = ({ TeacherID, StudentGroupID, TermID, StudentID }) =>
  `${TeacherID}-${TermID}-${StudentGroupID}-${StudentID}`;

export const createCombinedGroupReportID = ({ TeacherID, TermID, SubjectID, StudentGroupID }) =>
  `${TeacherID}-${TermID}-${SubjectID}-${StudentGroupID}`;

export const createCombinedStudentReportID = ({ TeacherID, TermID, SubjectID, StudentGroupID, StudentID }) =>
  `${TeacherID}-${TermID}-${SubjectID}-${StudentGroupID}-${StudentID}`;

export const reportsSlice = createSlice({
  initialState,
  name: 'reports',
  reducers: {
    actionGetGroupReportStart: state => {
      state.isGroupReportGetRequestLoading = true;
    },
    actionGetGroupReportError: state => {
      state.isGroupReportGetRequestLoading = false;
    },
    actionGetGroupReportSuccess: (state, action) => {
      state.isGroupReportGetRequestLoading = false;
      const { data, meta = {} } = action.payload;
      const combinedID = createCombinedGroupReportID(meta);
      state.groupsReportsDataHashList = {
        ...state.groupsReportsDataHashList,
        [combinedID]: data,
      };
    },

    actionSaveGroupReportStart: state => {
      state.isGroupReportGetRequestLoading = true;
    },
    actionSaveGroupReportError: state => {
      state.isGroupReportGetRequestLoading = false;
    },
    actionSaveGroupReportSuccess: (state, action) => {
      state.isGroupReportGetRequestLoading = false;
    },

    actionGetStudentReportStart: state => {
      state.isStudentReportGetRequestLoading = true;
    },
    actionGetStudentReportError: state => {
      state.isStudentReportGetRequestLoading = false;
    },
    actionGetStudentReportSuccess: (state, action) => {
      state.isStudentReportGetRequestLoading = false;
      const { data, meta = {} } = action.payload;
      const combinedID = createCombinedStudentReportID(meta);
      const currentData = state.studentReportsDataHashList[combinedID] || {};
      state.studentReportsDataHashList = {
        ...state.studentReportsDataHashList,
        [combinedID]: { ...currentData, ...data },
      };
    },

    actionGetStudentReportPreviewStart: state => {
      state.isStudentReportGetRequestLoading = true;
    },
    actionGetStudentReportPreviewError: state => {
      state.isStudentReportGetRequestLoading = false;
    },
    actionGetStudentReportPreviewSuccess: (state, action) => {
      state.isStudentReportGetRequestLoading = false;
      const { data, meta = {} } = action.payload;
      const combinedID = createCombinedStudentReportID(meta);
      const currentData = state.studentReportsDataHashList[combinedID] || {};
      state.studentReportsDataHashList = {
        ...state.studentReportsDataHashList,
        [combinedID]: { ...currentData, previewUrl: data },
      };
    },

    actionSaveStudentReportStart: state => {
      state.isStudentReportSaveRequestLoading = true;
    },
    actionSaveStudentReportError: state => {
      state.isStudentReportSaveRequestLoading = false;
    },
    actionSaveStudentReportSuccess: state => {
      state.isStudentReportSaveRequestLoading = true;
    },

    actionGetStudentReportsListStart: state => {
      state.studentReportsListData = undefined;
      state.isStudentReportsListRequestLoading = true;
    },
    actionGetStudentReportsListError: state => {
      state.isStudentReportsListRequestLoading = false;
    },
    actionGetStudentReportsListSuccess: (state, action) => {
      state.isStudentReportsListRequestLoading = false;
      state.studentReportsListData = action.payload;
    },

    actionGetFormReportsListStart: state => {
      state.formReportsListData = undefined;
      state.isFormReportsListRequestLoading = true;
    },
    actionGetFormReportsListError: state => {
      state.isFormReportsListRequestLoading = false;
    },
    actionGetFormReportsListSuccess: (state, action) => {
      state.isFormReportsListRequestLoading = false;
      state.formReportsListData = action.payload;
    },

    actionGetFormReportStart: state => {
      state.isFormReportGetRequestLoading = true;
    },
    actionGetFormReportError: state => {
      state.isFormReportGetRequestLoading = false;
    },
    actionGetFormReportSuccess: (state, action) => {
      const { data, meta = {} } = action.payload;
      const combinedID = createCombinedFormReportID(meta);
      const currentData = state.formReportsDataHashList[combinedID] || {};
      state.isFormReportGetRequestLoading = false;
      state.formReportsDataHashList = {
        ...state.formReportsDataHashList,
        [combinedID]: { ...currentData, ...data },
      };
    },
  },
});

export default reportsSlice.reducer;
export const {
  actionGetGroupReportStart,
  actionGetStudentReportSuccess,
  actionSaveStudentReportError,
  actionSaveStudentReportSuccess,
  actionSaveStudentReportStart,
  actionGetStudentReportError,
  actionGetStudentReportStart,
  actionGetGroupReportSuccess,
  actionGetGroupReportError,
  actionSaveGroupReportError,
  actionSaveGroupReportSuccess,
  actionSaveGroupReportStart,
  actionGetFormReportStart,
  actionGetFormReportError,
  actionGetFormReportSuccess,
  actionGetStudentReportPreviewError,
  actionGetStudentReportPreviewSuccess,
  actionGetStudentReportPreviewStart,
  actionGetStudentReportsListError,
  actionGetStudentReportsListStart,
  actionGetStudentReportsListSuccess,
  actionGetFormReportsListError,
  actionGetFormReportsListSuccess,
  actionGetFormReportsListStart,
} = reportsSlice.actions;
