import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import { PersistGate } from 'redux-persist/integration/react';

import { USER_DATA } from './consts';
import { actionUserLogout } from './State/actions/authActions';
import { actionGetTermsOfEntry, actionGetTrackingAssessed } from './State/actions/common';
import { actionGetFoldersData } from './State/actions/filesActions';
import { actionGetTeachersList } from './State/actions/studentTrackerActions';
import { clearGlobalErrors } from './State/slices/errors';
import { persistor } from './State/store';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

export function App() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector(state => state.auth);
  const { foldersHashObject = {} } = useSelector(state => state.files);
  if (isAuth && !localStorage.getItem(USER_DATA)) {
    dispatch(actionUserLogout());
  }
  useEffect(() => {
    dispatch(clearGlobalErrors());
    if (isAuth && !Object.keys(foldersHashObject).length) {
      dispatch(actionGetFoldersData());
    }
    if (isAuth) {
      dispatch(actionGetTeachersList());
      dispatch(actionGetTrackingAssessed());
      dispatch(actionGetTermsOfEntry());
    }
  }, [isAuth]);

  const isProd = process.env.REACT_APP_ENV === 'production';

  return isProd ? (
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </PersistGate>
  ) : (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
