import { SUBJECTS_GROUP_OTHERS } from '../../consts';

export const parseCommonResponse = response => {
  const { data: { Result = [] } = {} } = response;
  return Result;
};

export const parseFullTermsResponse = response => {
  const { data: { Result = [] } = {} } = response;
  const newArray = [...Result];
  return newArray.map(item => ({ ...item, SchoolTerm: item.Academicyear }));
};

export const parseStudentGroups = response => {
  const { data: { Result } = {} } = response;
  if (Result && Result[0] && Result[0].lstGroups) {
    return Result[0].lstGroups;
  }
  return [];
};

export const parseStudentGroupsAll = response => {
  const { data: { Result } = {} } = response;
  const allGroupsList = Result ? Result.map(teacher => [...teacher.lstGroups]) : [];
  let allGroupsArray = [];
  allGroupsList.forEach(groupList => {
    allGroupsArray = [...allGroupsArray, ...groupList];
  });

  const uniqueArray = [...new Map(allGroupsArray.map(item => [item.ID, item])).values()];

  return uniqueArray || [];
};

export const parseSubjectsResponse = response => {
  let parsedData = [];
  const { data: { Result = [] } = {} } = response;
  const subjectsData = Result[0];

  if (subjectsData) {
    const { SubjectsWithGroup = [] } = subjectsData;

    if (SubjectsWithGroup.length) {
      const othersGroup = SubjectsWithGroup.find(group => group.Group === SUBJECTS_GROUP_OTHERS);
      const othersGroupData = othersGroup ? othersGroup.lstSubject : [];
      const parsedOthersGroupData = othersGroupData.filter(subject => !subject.Name.includes(')'));

      const advancedGroupsData = SubjectsWithGroup.filter(
        group => group.Group !== SUBJECTS_GROUP_OTHERS && group.lstSubject?.length,
      );

      parsedData = [...parsedData, ...parsedOthersGroupData, ...advancedGroupsData];
    }
  }
  return parsedData;
};

export const parseSubjectsResponseSingle = response => {
  let parsedData = [];
  const { data: { Result = [] } = {} } = response;
  const subjectsData = Result[0];

  if (subjectsData) {
    const { SubjectsWithGroup = [] } = subjectsData;

    if (SubjectsWithGroup.length) {
      const othersGroup = SubjectsWithGroup.find(group => group.Group === SUBJECTS_GROUP_OTHERS);
      const othersGroupData = othersGroup ? othersGroup.lstSubject : [];
      const parsedOthersGroupData = othersGroupData.filter(subject => !subject.Name.includes(')'));
      parsedData = [...parsedData, ...parsedOthersGroupData];

      const advancedGroupsData = SubjectsWithGroup.filter(
        group => group.Group !== SUBJECTS_GROUP_OTHERS && group.lstSubject?.length,
      );
      advancedGroupsData.forEach(advancedGroup => {
        const firstLevelItem = advancedGroup.lstSubject?.length ? advancedGroup.lstSubject[0] : null;
        if (firstLevelItem) {
          parsedData = [...parsedData, firstLevelItem];
        }
      });
    }
  }
  return parsedData;
};

export const parseGoalsObjectivesResponse = response => {
  const { data: { Result } = {} } = response;
  if (Result) {
    const goalsArrayUniqueByKey = [...new Map(Result.map(item => [item.GoalID, item])).values()];
    const objectivesForGoals = {};
    Result.forEach(item => {
      if (item.ObjectiveID) {
        if (objectivesForGoals[item.GoalID]) {
          objectivesForGoals[item.GoalID].push(item);
        } else {
          objectivesForGoals[item.GoalID] = [item];
        }
      }
    });
    return goalsArrayUniqueByKey.map(goal => ({
      ...goal,
      ID: goal.GoalID,
      objectives: objectivesForGoals[goal.GoalID],
    }));
  }
  return [];
};

export const parseTeacherObjectives = response => {
  const { data: { Result = [] } = {} } = response;
  return [...new Map(Result.map(item => [item.ObjectiveID, item])).values()];
};
