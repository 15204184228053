import { USER_DATA } from '../../consts';
import {
  USER_LOGIN_REQUEST_START,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_REQUEST_ERROR,
  USER_LOGOUT,
} from '../constants/authConstants';

const initialState = {
  isAuth: !!localStorage.getItem(USER_DATA),
  userData: localStorage.getItem(USER_DATA) ? JSON.parse(localStorage.getItem(USER_DATA)) : undefined,
  isUserLoginRequestLoading: false,
  userLoginRequestErrors: [],
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST_START:
      return {
        ...state,
        userRole: '',
        userData: {},
        isUserLoginRequestLoading: true,
        userLoginRequestErrors: [],
      };
    case USER_LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        isUserLoginRequestLoading: false,
        userLoginRequestErrors: [],
        userData: action.payload,
        isAuth: true,
      };
    case USER_LOGIN_REQUEST_ERROR:
      return {
        ...state,
        isUserLoginRequestLoading: false,
        userLoginRequestErrors: action.payload,
        userData: {},
        isAuth: false,
      };
    case USER_LOGOUT:
      return { ...state, isAuth: false, userData: {} };
    default:
      return state;
  }
};
