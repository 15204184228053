import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  termDatesArray: [],
  termDatesHashList: {},
  lastDataLoadedTime: 0,
};

export const termDatesManagementSlice = createSlice({
  initialState,
  name: 'termDates',
  reducers: {
    getTermDatesRequestStart: state => {
      state.lastDataLoadedTime = 0;
    },
    getTermDatesRequestSuccess: (state, action) => {
      state.termDatesArray = action.payload;
      state.lastDataLoadedTime = new Date().getTime();
      const newTermDatesHashList = {};
      action.payload.forEach(group => {
        newTermDatesHashList[group.ID] = group;
      });
      state.termDatesHashList = newTermDatesHashList;
    },
    getTermDatesRequestError: state => {
      state.lastDataLoadedTime = new Date().getTime();
    },
    isAddUpdateTermDateStart: state => {
      state.isAddUpdateTermDateLoading = true;
    },
    isAddUpdateTermDateFinish: state => {
      state.isAddUpdateTermDateLoading = false;
    },
  },
});

export default termDatesManagementSlice.reducer;
export const {
  isAddUpdateTermDateFinish,
  isAddUpdateTermDateStart,
  getTermDatesRequestStart,
  getTermDatesRequestSuccess,
  getTermDatesRequestError,
} = termDatesManagementSlice.actions;
