export const studentsPageValues = {
  displayArchivedStudentsValue: 'Y',
  hideArchivedStudentsValue: 'N',
  archivedStudentStatus: '0',
  activeStudentStatus: '1',
  getListAPIData: {
    method: 'POST',
    url: '/AthenaStudent/Students/List',
  },
  getItemAPIData: {
    method: 'GET',
    url: '/AthenaStudent/Students',
  },
  updateItemAPIData: {
    method: 'POST',
    url: '/AthenaStudent/Students/Update',
  },
  pageUrl: '/students',
  filesFolders: {
    certificates: 'Current Students\\Certificates',
    documents: 'Current Students\\Documents',
    finances: 'Current Students\\Finances',
    evidence: 'Student Tracking\\Evidence',
    reports: 'Current Students\\Reports\\Student Reports'
  },
  defaultPaginationOptions: {
    SizePage: 15,
    OrderField: 'StartDate',
    OrderType: 'DESC',
  },
  columnSearchTimeout: 400,
  tableColumns: [
    { key: 'ID', label: 'ID' },
    { key: 'URN', label: 'URN' },
    {
      key: 'Name',
      label: 'Student Name',
    },
    { key: 'lastName', filter: false, sorter: false },
    { key: 'YearGroup' },
    { key: 'StartDate' },
    { key: 'Status', filter: false },
  ],
};
