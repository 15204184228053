import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {
  TARGETS_SUBLIST_AUTUMN,
  TARGETS_SUBLIST_AVERAGE_STUDENTS_TARGET,
  TARGETS_SUBLIST_SINGLE_GOAL_PROGRESS,
  TARGETS_SUBLIST_SPRING,
  TARGETS_SUBLIST_STUDENTS_PROGRESS_FOR_ALL_GOALS,
  TARGETS_SUBLIST_SUMMER,
  UPDATE_STUDENT_CAP4_API_URL,
} from '../../consts';
import axiosExt from "../utils/request";

const initialState = {
  teachersListDataArray: [],
  teachersListDataHashObject: {},
  teachersListErrors: [],
  isTeachersListGetDataLoading: false,
  selectedTeacher: {},

  trackingGoalsDataArray: [],
  trackingGoalsDataHashObject: {},
  trackingGoalsErrors: [],
  isTrackingGoalsGetDataLoading: false,

  studentsGroupsDataArray: [],
  studentsGroupsDataHashObject: {},
  studentsGroupsErrors: [],
  isStudentsGroupsGetDataLoading: false,
  selectedStudentsGroup: {},

  trackingObjectivesDataArray: [],
  trackingObjectivesDataHashObject: {},
  trackingObjectivesErrors: [],
  isTrackingObjectivesGetDataLoading: false,
  selectedTrackingObjective: {},

  trackingsDataArray: [],
  trackingsDataHashObject: {},
  trackingsErrors: [],
  isTrackingsGetDataLoading: false,
  isTrackingsAddDataLoading: false,

  teachersObjectivesDataHashList: {},
  teachersObjectivesGetDataRequestErrors: [],
  teachersObjectivesAddDataRequestErrors: [],
  teachersObjectivesDeleteDataRequestErrors: [],
  isTeachersObjectivesGetDataLoading: false,
  isTeachersObjectivesAddDataLoading: false,
  isTeachersObjectivesDeleteDataLoading: false,

  goalPercentageDataHashList: {},
  goalPercentageDataRequestErrors: [],
  isGoalPercentageDataLoading: false,

  selectedSubjectIDForSelectedTeacher: 0,
  selectedTrackingGoal: {},
  objectiveEditData: {},

  goalAverageTargets: {},
  goalAverageTargetsRequestErrors: [],
};

export const actionUpdateStudentProgressTableColumn = createAsyncThunk('student/cat4', async (requestData) => {
  const response = await axiosExt({
    method: 'POST',
    url: UPDATE_STUDENT_CAP4_API_URL,
    data: requestData,
  });
  return response?.data?.Result || [];
});

export const studentTrackerSlice = createSlice({
  initialState,
  name: 'table',
  reducers: {
    getTeachersListDataRequestStart: state => {
      state.isTeachersListGetDataLoading = true;
      state.teachersListErrors = [];
    },
    getTeachersListDataRequestSuccess: (state, action) => {
      const teachersListDataHashObject = {};
      action.payload.forEach(teacher => {
        teachersListDataHashObject[teacher.ID] = teacher;
      });
      const activeTeachersDataArray = action.payload.filter(teacher => teacher.Status === 1);
      state.isTeachersListGetDataLoading = false;
      state.teachersListErrors = [];
      state.teachersListDataHashObject = teachersListDataHashObject;
      state.teachersListDataArray = activeTeachersDataArray;
      state.allTeachersListDataArray = action.payload;
    },
    getTeachersListDataRequestError: (state, action) => {
      state.isTeachersListGetDataLoading = false;
      state.teachersListErrors = action.payload;
    },
    changeTeacherStatusRequestSuccess: (state, action) => {
      const { meta: { teacherID, status } = {} } = action.payload;
      const newTeachers = state.teachersListDataArray.map(teacher => {
        if (teacher.ID === teacherID) {
          return { ...teacher, Status: status };
        }
        return teacher;
      });
      state.teachersListDataHashObject = {
        ...state.teachersListDataHashObject,
        [teacherID]: { ...state.teachersListDataHashObject[teacherID], Status: status },
      };
      state.teachersListDataArray = newTeachers;
    },

    getTrackingGoalsDataRequestStart: state => {
      state.isTrackingGoalsGetDataLoading = true;
      state.trackingGoalsErrors = [];
    },
    getTrackingGoalsDataRequestSuccess: (state, action) => {
      const { data, meta: { StudentGroupID, SubjectID } = {} } = action.payload;
      state.isTrackingGoalsGetDataLoading = true;
      state.trackingGoalsErrors = [];
      state.trackingGoalsDataHashObject = {
        ...state.trackingGoalsDataHashObject,
        [`${StudentGroupID}${SubjectID}`]: data,
      };
      state.trackingGoalsDataArray = data;
    },
    getTrackingGoalsDataRequestError: (state, action) => {
      state.isTrackingGoalsGetDataLoading = false;
      state.trackingGoalsErrors = action.payload;
    },

    getStudentsGroupsDataRequestStart: state => {
      state.isStudentsGroupsGetDataLoading = true;
      state.studentsGroupsErrors = [];
    },
    getStudentsGroupsDataRequestSuccess: (state, action) => {
      const studentsGroupsDataHashObject = {};
      action.payload.forEach(teacher => {
        studentsGroupsDataHashObject[teacher.ID] = teacher;
      });
      state.isStudentsGroupsGetDataLoading = false;
      state.studentsGroupsErrors = [];
      state.studentsGroupsDataHashObject = studentsGroupsDataHashObject;
      state.studentsGroupsDataArray = action.payload;
    },
    getStudentsGroupsDataRequestError: (state, action) => {
      state.isStudentsGroupsGetDataLoading = false;
      state.studentsGroupsErrors = action.payload;
    },

    getTrackingObjectivesDataRequestStart: state => {
      state.isTrackingObjectivesGetDataLoading = true;
      state.trackingObjectivesErrors = [];
    },
    getTrackingObjectivesDataRequestSuccess: (state, action) => {
      const { data, meta } = action.payload;
      const { GoalID, SubjectID } = meta;
      const dataHashList = {};
      data.forEach(objective => {
        dataHashList[objective.ObjectiveID] = objective;
      });
      state.isTrackingObjectivesGetDataLoading = false;
      state.trackingObjectivesErrors = [];
      state.trackingObjectivesDataHashObject = {
        ...state.trackingObjectivesDataHashObject,
        [`${GoalID}${SubjectID}`]: {
          dataArray: data,
          dataHashList,
        },
      };
    },
    getTrackingObjectivesDataRequestError: (state, action) => {
      state.isTrackingObjectivesGetDataLoading = false;
      state.trackingObjectivesErrors = action.payload;
    },

    getTrackingsDataRequestStart: state => {
      state.isTrackingsGetDataLoading = true;
      state.trackingsErrors = [];
    },
    getTrackingsDataRequestSuccess: (state, action) => {
      const trackingsDataHashObject = {};
      action.payload.forEach(tracking => {
        trackingsDataHashObject[`${tracking.StudentID}${tracking.GoalID}${tracking.SubjectID}${tracking.ObjectiveID}`] =
          tracking;
      });
      state.isTrackingsGetDataLoading = false;
      state.trackingsErrors = [];
      state.trackingsDataHashObject = trackingsDataHashObject;
      state.trackingsDataArray = action.payload;
    },
    getTrackingsDataRequestError: (state, action) => {
      state.isTrackingsGetDataLoading = false;
      state.trackingsErrors = action.payload;
    },

    addTrackingsDataRequestStart: state => {
      state.isTrackingsGetDataLoading = true;
      state.trackingsErrors = [];
    },
    addTrackingsDataRequestSuccess: state => {
      state.isTrackingsAddDataLoading = false;
      state.trackingsErrors = [];
    },
    addTrackingsDataRequestError: (state, action) => {
      state.isTrackingsGetDataLoading = false;
      state.trackingsErrors = action.payload;
    },

    selectTeacher: (state, action) => {
      state.selectedTeacher = action.payload;
      state.selectedStudentsGroup = {};
      state.selectedSubjectIDForSelectedTeacher = 0;
      state.studentsGroupsDataArray = [];
      state.studentsGroupsDataHashObject = {};
      state.selectedTrackingGoal = {};
      state.trackingGoalsDataArray = [];
      state.trackingGoalsDataHashObject = {};
    },
    selectStudentsGroup: (state, action) => {
      state.selectedStudentsGroup = action.payload;
      state.trackingGoalsDataArray = [];
      state.trackingGoalsDataHashObject = {};
    },
    selectSubjectForSelectedTeacher: (state, action) => {
      state.selectedStudentsGroup = {};
      state.selectedSubjectIDForSelectedTeacher = action.payload;
      state.studentsGroupsDataArray = [];
      state.studentsGroupsDataHashObject = {};
      state.trackingGoalsDataArray = [];
      state.trackingGoalsDataHashObject = {};
    },
    selectTrackingGoal: (state, action) => {
      state.selectedTrackingGoal = action.payload;
    },

    setObjectiveEditData: (state, action) => {
      state.objectiveEditData = action.payload;
    },
    clearObjectiveEditData: state => {
      state.objectiveEditData = {};
    },

    getTeacherObjectivesDataRequestStart: state => {
      state.isTeachersObjectivesGetDataLoading = true;
    },
    getTeacherObjectivesDataRequestError: (state, action) => {
      state.teachersObjectivesGetDataRequestErrors = action.payload;
      state.isTeachersObjectivesGetDataLoading = false;
    },
    getTeacherObjectivesDataRequestSuccess: (state, action) => {
      const { meta: { TeacherID, GoalID, SubjectID, GoalsObjectivesGroupsID } = {}, data } = action.payload;
      state.isTeachersObjectivesGetDataLoading = false;
      state.teachersObjectivesDataHashList = {
        ...state.teachersObjectivesDataHashList,
        [`${TeacherID}${GoalID}${SubjectID}${GoalsObjectivesGroupsID}`]: data,
      };
    },

    addTeacherObjectivesDataRequestStart: state => {
      state.isTeachersObjectivesAddDataLoading = true;
    },
    addTeacherObjectivesDataRequestError: (state, action) => {
      state.teachersObjectivesAddDataRequestErrors = action.payload;
      state.isTeachersObjectivesAddDataLoading = false;
    },
    addTeacherObjectivesDataRequestSuccess: state => {
      state.isTeachersObjectivesAddDataLoading = false;
    },

    deleteTeacherObjectivesDataRequestStart: state => {
      state.isTeachersObjectivesAddDataLoading = true;
    },
    deleteTeacherObjectivesDataRequestError: (state, action) => {
      state.teachersObjectivesDeleteDataRequestErrors = action.payload;
      state.isTeachersObjectivesDeleteDataLoading = false;
    },
    deleteTeacherObjectivesDataRequestSuccess: state => {
      state.isTeachersObjectivesDeleteDataLoading = false;
    },

    getGoalPercentageDataRequestStart: state => {
      state.isGoalPercentageDataLoading = true;
    },
    getGoalPercentageDataRequestError: (state, action) => {
      state.isGoalPercentageDataLoading = false;
      state.goalPercentageDataRequestErrors = action.payload;
    },
    getGoalPercentageDataRequestSuccess: (state, action) => {
      const { meta, data } = action.payload;
      const { SubjectID, GoalID, GoalsObjectivesGroupsID, TermID } = meta;
      state.isGoalPercentageDataLoading = false;
      state.goalPercentageDataHashList = {
        ...state.goalPercentageDataHashList,
        [`${GoalID}${SubjectID}${GoalsObjectivesGroupsID}${TermID}`]: data,
      };
    },
    getGroupProgressExtendedDataRequestStart: state => {
      state.isGroupProgressExtendedDataLoading = true;
    },
    getGroupProgressExtendedDataRequestError: (state, action) => {
      state.isGroupProgressExtendedDataLoading = false;
      state.groupProgressForAllGoalsDataRequestErrors = action.payload;
    },
    getGroupProgressExtendedDataRequestSuccess: (state, action) => {
      const {
        data,
        meta: { SubjectID, StudentGroupID },
      } = action.payload;

      const totalGroupProgressDataByAcademicTerm = {
        spring: data.find(listItem => listItem.Key === TARGETS_SUBLIST_SPRING)?.Value,
        summer: data.find(listItem => listItem.Key === TARGETS_SUBLIST_SUMMER)?.Value,
        autumn: data.find(listItem => listItem.Key === TARGETS_SUBLIST_AUTUMN)?.Value,
      };
      const studentsPercentages = data.find(
        listItem => listItem.Key === TARGETS_SUBLIST_STUDENTS_PROGRESS_FOR_ALL_GOALS,
      )?.Value;
      const totalAverageTargetsData = data.find(
        listItem => listItem.Key === TARGETS_SUBLIST_AVERAGE_STUDENTS_TARGET,
      )?.Value;
      const singleGoalsTargetsData = data.find(
        listItem => listItem.Key === TARGETS_SUBLIST_SINGLE_GOAL_PROGRESS,
      )?.Value;

      const studentsPercentagesHashObject = {};
      studentsPercentages.forEach(studentProgress => {
        studentsPercentagesHashObject[studentProgress?.StudentID] = studentProgress?.Percent;
      });
      const studentsAverageTargetsObject = {};
      totalAverageTargetsData.forEach(studentAverageTarget => {
        studentsAverageTargetsObject[
          `${SubjectID}${StudentGroupID}${studentAverageTarget?.GoalID}${studentAverageTarget?.StudentID}`
        ] = studentAverageTarget?.AverageStudentTargetIDforGoal;
      });
      const singleGoalsTargetsHashObject = {};
      singleGoalsTargetsData.forEach(singleGoalData => {
        const currentData =
          singleGoalsTargetsHashObject[`${SubjectID}${StudentGroupID}${singleGoalData?.GoalID}`] || [];
        singleGoalsTargetsHashObject[`${SubjectID}${StudentGroupID}${singleGoalData?.GoalID}`] = currentData
          ? [...currentData, singleGoalData]
          : [singleGoalData];
      });

      state.isGroupProgressExtendedDataLoading = false;
      state.goalAverageTargets = studentsAverageTargetsObject;
      state.totalGroupProgressDataByAcademicTerm = totalGroupProgressDataByAcademicTerm;
      state.studentsProgressDataHashObject = studentsPercentagesHashObject;
      state.singleGoalsTargetsHashObject = singleGoalsTargetsHashObject;
    },
  },
});

export default studentTrackerSlice.reducer;
export const {
  getStudentsGroupsDataRequestError,
  getStudentsGroupsDataRequestSuccess,
  getStudentsGroupsDataRequestStart,
  getTrackingGoalsDataRequestError,
  getTrackingGoalsDataRequestSuccess,
  getTrackingGoalsDataRequestStart,
  getTeachersListDataRequestError,
  getTeachersListDataRequestSuccess,
  getTeachersListDataRequestStart,
  getTrackingObjectivesDataRequestError,
  getTrackingObjectivesDataRequestStart,
  getTrackingObjectivesDataRequestSuccess,
  getTrackingsDataRequestError,
  getTrackingsDataRequestSuccess,
  getTrackingsDataRequestStart,
  addTrackingsDataRequestStart,
  addTrackingsDataRequestError,
  addTrackingsDataRequestSuccess,
  selectStudentsGroup,
  selectSubjectForSelectedTeacher,
  selectTeacher,
  selectTrackingGoal,
  setObjectiveEditData,
  clearObjectiveEditData,
  getGoalPercentageDataRequestError,
  getGoalPercentageDataRequestSuccess,
  getTeacherObjectivesDataRequestError,
  getTeacherObjectivesDataRequestStart,
  getTeacherObjectivesDataRequestSuccess,
  getGoalPercentageDataRequestStart,
  addTeacherObjectivesDataRequestError,
  addTeacherObjectivesDataRequestStart,
  addTeacherObjectivesDataRequestSuccess,
  getGroupProgressExtendedDataRequestError,
  getGroupProgressExtendedDataRequestStart,
  getGroupProgressExtendedDataRequestSuccess,
  deleteTeacherObjectivesDataRequestError,
  deleteTeacherObjectivesDataRequestStart,
  deleteTeacherObjectivesDataRequestSuccess,
  changeTeacherStatusRequestSuccess,
} = studentTrackerSlice.actions;
