import axios from 'axios';

import { BASE_API_URL, USER_DATA } from '../../consts';

const fetchClient = () => {
  const defaultOptions = {
    baseURL: BASE_API_URL,
    method: 'get',
    headers: {
      'Accept-Language': 'en',
      'Content-Type': 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const { Token } = JSON.parse(localStorage.getItem(USER_DATA)) || {};
    config.headers.Authorization = Token || '';
    return config;
  });

  return instance;
};

export default fetchClient();
