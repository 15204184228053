import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  studentGroupsArray: [],
  studentGroupsHashList: {},
  lastDataLoadedTime: 0,
  isUpdateGroupDataLoading: false,
};

export const studentGroupsManagementSlice = createSlice({
  initialState,
  name: 'studentGroups',
  reducers: {
    getStudentGroupsRequestStart: state => {
      state.lastDataLoadedTime = 0;
    },
    getStudentGroupsRequestSuccess: (state, action) => {
      state.studentGroupsArray = action.payload;
      state.lastDataLoadedTime = new Date().getTime();
      const newStudentGroupsHashList = {};
      action.payload.forEach(group => {
        newStudentGroupsHashList[group.ID] = group;
      });
      state.studentGroupsHashList = newStudentGroupsHashList;
    },
    getStudentGroupsRequestError: state => {
      state.lastDataLoadedTime = new Date().getTime();
    },

    updateGroupDataRequestSuccess: (state, action) => {
      state.isUpdateGroupDataLoading = false;
      const { StudentIDs, GroupID } = action.payload;
      state.studentGroupsArray = state.studentGroupsArray.map(studentGroup => {
        if (studentGroup.ID == GroupID) {
          return { ...studentGroup, StudentIDs };
        }
        return studentGroup;
      });
      state.studentGroupsHashList = {
        ...state.studentGroupsHashList,
        [GroupID]: { ...state.studentGroupsHashList[GroupID], StudentIDs },
      };
    },
  },
});

export default studentGroupsManagementSlice.reducer;
export const {
  getStudentGroupsRequestStart,
  updateGroupDataRequestSuccess,
  getStudentGroupsRequestSuccess,
  getStudentGroupsRequestError,
} = studentGroupsManagementSlice.actions;
