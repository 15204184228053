import { createSlice } from '@reduxjs/toolkit';

import { recordLastError } from '../../helpers';

export const relativeSelectPickersSlice = createSlice({
  initialState: {},
  name: 'relativeSelectPickers',
  reducers: {
    actionRelativeSelectPickerReset: (state, action) => {
      const { payload: { dataID, dataSetID } = {} } = action;
      const currentDataSetState = { ...state[dataSetID] };
      state[dataSetID] = { ...currentDataSetState, [dataID]: undefined, lastDataSetUpdatedTime: new Date().getTime() };
    },
    actionRelativeSelectPickerChange: (state, action) => {
      const { payload: { dataID, dataSetID, value, dataSourceSelectedItemID } = {} } = action;
      const currentDataSetState = { ...state[dataSetID] };
      const currentDataState = { ...currentDataSetState[dataID] };
      state[dataSetID] = {
        ...currentDataSetState,
        [dataID]: { ...currentDataState, selectedItem: value, dataSourceSelectedItemID },
        lastDataSetUpdatedTime: new Date().getTime(),
      };
    },
    actionRelativeSelectPickerDataRequestStart: (state, action) => {
      const { payload: { dataID, dataSetID } = {} } = action;
      const currentDataSetState = { ...state[dataSetID] };
      state[dataSetID] = {
        ...currentDataSetState,
        [dataID]: { fetchedDataHashID: undefined, selectedItem: undefined, itemsArray: [], isDataLoading: true },
      };
    },
    actionRelativeSelectPickerDataRequestSuccess: (state, action) => {
      const { payload: { dataID, dataSetID, Result, dataHashID } = {} } = action;
      const currentDataSetState = { ...state[dataSetID] };
      state[dataSetID] = {
        ...currentDataSetState,
        [dataID]: { fetchedDataHashID: dataHashID, selectedItem: undefined, itemsArray: Result, isDataLoading: false },
      };
    },
    updateRelativeSelectPickerDataSet: (state, action) => {
      const { payload: { dataID, dataSetID, newData } = {} } = action;
      const currentDataSetState = { ...state[dataSetID] };
      state[dataSetID] = { ...currentDataSetState, [dataID]: newData };
    },
    actionRelativeSelectPickerBackup: (state, action) => {
      const { payload: { dataSetID } = {} } = action;
      const currentDataSetState = { ...state[dataSetID] };
      state[dataSetID] = { ...currentDataSetState, lastBackupData: currentDataSetState };
    },
    actionRelativeSelectPickerLoadData: (state, action) => {
      const { payload: { dataSetID, newData } = {} } = action;
      state[dataSetID] = { ...newData, lastBackupData: newData };
    },
    actionRelativeSelectPickerRollback: (state, action) => {
      const { payload: { dataSetID } = {} } = action;
      const dataSetBackup = { ...state[dataSetID]?.lastBackupData, lastDataUpdateTime: new Date().getTime() };
      state[dataSetID] = { ...dataSetBackup, lastBackupData: dataSetBackup };
    },
  },
});

export default relativeSelectPickersSlice.reducer;
export const {
  updateRelativeSelectPickerDataSet,
  actionRelativeSelectPickerDataRequestStart,
  actionRelativeSelectPickerReset,
  actionRelativeSelectPickerDataRequestSuccess,
  actionRelativeSelectPickerChange,
  actionRelativeSelectPickerBackup,
  actionRelativeSelectPickerRollback,
  actionRelativeSelectPickerLoadData,
} = relativeSelectPickersSlice.actions;
