import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  goalsArray: [],
  objectivesHashList: {},
  subjectsArray: [],
  subjectsHashList: {},
  studentGroupsArray: [],
  studentGroupsAllHashList: {},
  studentGroupsBySubjectHashList: {},
  selectedStudentsGroup: '',
  selectedSubjectIDForAllTeachers: '',
};

export const goalsAndObjectivesSlice = createSlice({
  initialState,
  name: 'goalsAndObjectives',
  reducers: {
    getGoalsRequestSuccess: (state, action) => {
      state.goalsArray = action.payload.goals;
      state.objectivesHashList = action.payload.objectives;
    },
    getStudentGroupsSuccess: (state, action) => {
      const { data: newGroupsArray, meta: { subjectID } , isForSelectPicker } = action.payload;
      const newStudentGroupsAllHashList = {...state.studentGroupsAllHashList};
      newGroupsArray.forEach(group => {
        if (!newStudentGroupsAllHashList[group.ID]) {
          newStudentGroupsAllHashList[group.ID] = group;
        }
      })
      if (isForSelectPicker) {
        state.studentGroupsArray = newGroupsArray;
      }
      if (subjectID) {
        state.studentGroupsBySubjectHashList = {...state.studentGroupsBySubjectHashList, [subjectID]: newGroupsArray}
      }
      state.studentGroupsAllHashList = newStudentGroupsAllHashList;

    },
    getSubjectsRequestSuccess: (state, action) => {
      const subjectsArray = action.payload.data;
      state.subjectsArray = subjectsArray;
      const subjectsHashList = {};
      subjectsArray.forEach(subject => {
        subjectsHashList[subject.ID] = subject;
      })
      state.subjectsHashList = subjectsHashList;
    },
    getObjectivesRequestSuccess: (state, action) => {
      const { payload, meta: { GoalID } = {} } = action;
      state.objectivesHashList = { ...state.objectivesHashList, [GoalID]: payload };
    },
    selectSubjectForGoalsPage: (state, action) => {
      state.selectedSubjectIDForAllTeachers = action.payload;
    },
    selectStudentsGroupForGoalsPage: (state, action) => {
      state.selectedStudentsGroup = action.payload;
    },
  },
});

export default goalsAndObjectivesSlice.reducer;
export const {
  getGoalsRequestSuccess,
  selectStudentsGroupForGoalsPage,
  selectSubjectForGoalsPage,
  getSubjectsRequestSuccess,
  getStudentGroupsSuccess,
} = goalsAndObjectivesSlice.actions;