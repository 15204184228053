export const ADMISSION_LIST_REQUEST = 'ADMISSION_LIST_REQUEST';
export const ADMISSION_LIST_SUCCESS = 'ADMISSION_LIST_SUCCESS';
export const ADMISSION_LIST_FAIL = 'ADMISSION_LIST_FAIL';
export const RESET_CURRENT_ADMISSION = 'RESET_CURRENT_ADMISSION';

export const ADMISSION_LIST_DETAIL_REQUEST = 'ADMISSION_LIST_DETAIL_REQUEST';
export const ADMISSION_LIST_DETAIL_SUCCESS = 'ADMISSION_LIST_DETAIL_SUCCESS';
export const ADMISSION_LIST_DETAIL_FAIL = 'ADMISSION_LIST_DETAIL_FAIL';

// for changing the status of any admission on the table
export const ADMISSION_LIST_STATUS_CHANGE_REQUEST = 'ADMISSION_LIST_STATUS_CHANGE_REQUEST';
export const ADMISSION_LIST_STATUS_CHANGE_SUCCESS = 'ADMISSION_LIST_STATUS_CHANGE_SUCCESS';
export const ADMISSION_LIST_STATUS_CHANGE_FAIL = 'ADMISSION_LIST_STATUS_CHANGE_FAIL';

// for changing the status of the current global admission detail
export const ADMISSION_LIST_STATUS_CHANGE2_REQUEST = 'ADMISSION_LIST_STATUS_CHANGE2_REQUEST';
export const ADMISSION_LIST_STATUS_CHANGE2_SUCCESS = 'ADMISSION_LIST_STATUS_CHANGE2_SUCCESS';
export const ADMISSION_LIST_STATUS_CHANGE2_FAIL = 'ADMISSION_LIST_STATUS_CHANGE2_FAIL';

export const ADMISSION_LIST_STUDENTS_ADMISSION_DETAILS_REQUEST = 'ADMISSION_LIST_STUDENTS_ADMISSION_DETAILS_REQUEST';
export const ADMISSION_LIST_STUDENTS_ADMISSION_DETAILS_SUCCESS = 'ADMISSION_LIST_STUDENTS_ADMISSION_DETAILS_SUCCESS';
export const ADMISSION_LIST_STUDENTS_ADMISSION_DETAILS_FAIL = 'ADMISSION_LIST_STUDENTS_ADMISSION_DETAILS_FAIL';

export const ADMISSION_LIST_STUDENTS_VISIBLE_REQUEST = 'ADMISSION_LIST_STUDENTS_VISIBLE_REQUEST';
export const ADMISSION_LIST_STUDENTS_VISIBLE_SUCCESS = 'ADMISSION_LIST_STUDENTS_VISIBLE_SUCCESS';
export const ADMISSION_LIST_STUDENTS_VISIBLE_FAIL = 'ADMISSION_LIST_STUDENTS_VISIBLE_FAIL';

export const ADMISSION_LIST_STUDENTS_ADD_REQUEST = 'ADMISSION_LIST_STUDENTS_ADD_REQUEST';
export const ADMISSION_LIST_STUDENTS_ADD_SUCCESS = 'ADMISSION_LIST_STUDENTS_ADD_SUCCESS';
export const ADMISSION_LIST_STUDENTS_ADD_FAIL = 'ADMISSION_LIST_STUDENTS_ADD_FAIL';

export const ADMISSION_LIST_STUDENTS_DELETE_REQUEST = 'ADMISSION_LIST_STUDENTS_DELETE_REQUEST';
export const ADMISSION_LIST_STUDENTS_DELETE_SUCCESS = 'ADMISSION_LIST_STUDENTS_DELETE_SUCCESS';
export const ADMISSION_LIST_STUDENTS_DELETE_FAIL = 'ADMISSION_LIST_STUDENTS_DELETE_FAIL';

export const ADMISSION_LIST_DETAIL_SAVE_REQUEST = 'ADMISSION_LIST_DETAIL_SAVE_REQUEST';
export const ADMISSION_LIST_DETAIL_SAVE_SUCCESS = 'ADMISSION_LIST_DETAIL_SAVE_SUCCESS';
export const ADMISSION_LIST_DETAIL_SAVE_FAIL = 'ADMISSION_LIST_DETAIL_SAVE_FAIL';

export const ADMISSION_LIST_NOTE_DELETE_REQUEST = 'ADMISSION_LIST_NOTE_DELETE_REQUEST';
export const ADMISSION_LIST_NOTE_DELETE_SUCCESS = 'ADMISSION_LIST_NOTE_DELETE_SUCCESS';
export const ADMISSION_LIST_NOTE_DELETE_FAIL = 'ADMISSION_LIST_NOTE_DELETE_FAIL';

export const ADMISSION_LIST_NOTE_ADD_REQUEST = 'ADMISSION_LIST_NOTE_ADD_REQUEST';
export const ADMISSION_LIST_NOTE_ADD_SUCCESS = 'ADMISSION_LIST_NOTE_ADD_SUCCESS';
export const ADMISSION_LIST_NOTE_ADD_FAIL = 'ADMISSION_LIST_NOTE_ADD_FAIL';

export const ADMISSION_LIST_STUDENTS_SAVE_REQUEST = 'ADMISSION_LIST_STUDENTS_SAVE_REQUEST';
export const ADMISSION_LIST_STUDENTS_SAVE_SUCCESS = 'ADMISSION_LIST_STUDENTS_SAVE_SUCCESS';
export const ADMISSION_LIST_STUDENTS_SAVE_FAIL = 'ADMISSION_LIST_STUDENTS_SAVE_FAIL';

export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE';

export const GET_ADMISSIONS_YEAR_GROUPS_REQUEST_SUCCESS = 'GET_ADMISSIONS_YEAR_GROUPS_REQUEST_SUCCESS';
export const GET_ADMISSIONS_ACTIONS_CODES_REQUEST_SUCCESS = 'GET_ADMISSIONS_ACTIONS_CODES_REQUEST_SUCCESS';
export const GET_ADMISSIONS_ACADEMIC_YEARS_REQUEST_SUCCESS = 'GET_ADMISSIONS_ACADEMIC_YEARS_REQUEST_SUCCESS';
export const GET_ADMISSIONS_TERMS_OF_ENTRY_REQUEST_SUCCESS = 'GET_ADMISSIONS_TERMS_OF_ENTRY_REQUEST_SUCCESS';
