import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subjectsArray: [],
  subjectsHashList: {},
  lastDataLoadedTime: 0,
};

export const subjectsManagementSlice = createSlice({
  initialState,
  name: 'subjects',
  reducers: {
    getSubjectsRequestStart: state => {
      state.lastDataLoadedTime = 0;
    },
    getSubjectsRequestSuccess: (state, action) => {
      state.subjectsArray = action.payload;
      state.lastDataLoadedTime = new Date().getTime();
      const newSubjectsHashList = {};
      action.payload.forEach(group => {
        newSubjectsHashList[group.ID] = group;
      });
      state.subjectsHashList = newSubjectsHashList;
    },
    getSubjectsRequestError: state => {
      state.lastDataLoadedTime = new Date().getTime();
    },
  },
});

export default subjectsManagementSlice.reducer;
export const { getSubjectsRequestStart, getSubjectsRequestSuccess, getSubjectsRequestError } =
  subjectsManagementSlice.actions;
