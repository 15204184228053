import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  emailTemplatesArray: [],
  emailTemplatesHashList: {},
  lastDataLoadedTime: 0,
  selectedTemplateID: 0,
};

export const emailTemplatesSlice = createSlice({
  initialState,
  name: 'emailTemplates',
  reducers: {
    getEmailTemplatesRequestStart: state => {
      state.lastDataLoadedTime = 0;
    },
    getEmailTemplatesRequestSuccess: (state, action) => {
      state.emailTemplatesArray = action.payload;
      state.lastDataLoadedTime = new Date().getTime();
      const newEmailTemplatesHashList = {};
      action.payload.forEach(group => {
        newEmailTemplatesHashList[group.ID] = group;
      });
      state.emailTemplatesHashList = newEmailTemplatesHashList;
    },
    getEmailTemplatesRequestError: state => {
      state.lastDataLoadedTime = new Date().getTime();
    },
    selectTemplate: (state, action) => {
      state.selectedTemplateID = action.payload;
    },
    updateTemplate: (state, action) => {
      const { ID } = action.payload;
      state.emailTemplatesArray = state.emailTemplatesArray.map(item => (item.ID === ID ? action.payload : item));
      state.emailTemplatesHashList = { ...state.emailTemplatesHashList, [ID]: action.payload };
    },
  },
});

export default emailTemplatesSlice.reducer;
export const {
  selectTemplate,
  getEmailTemplatesRequestStart,
  getEmailTemplatesRequestSuccess,
  getEmailTemplatesRequestError,
  updateTemplate,
} = emailTemplatesSlice.actions;
