import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subjectsAdvancedForStudentTrackerData: [],
  subjectsAdvancedForStudentTrackerLoading: false,
  selectedSubjectForStudentTracker: {},
  subjectsAdvancedForAttendancePageData: [],
  subjectsAdvancedForAttendancePageLoading: false,
  selectedSubjectForAttendancePage: {},
};

export const subjectsAdvancedSlice = createSlice({
  initialState,
  name: 'subjectsAdvanced',
  reducers: {
    getSubjectAdvancedForStudentTrackerRequestStart: (state) => {
      state.subjectsAdvancedForStudentTrackerLoading = true;
    },
    getSubjectAdvancedForStudentTrackerRequestSuccess: (state, action) => {
      state.subjectsAdvancedForStudentTrackerLoading = false;
      state.subjectsAdvancedForStudentTrackerData = action.payload;
    },
    getSubjectAdvancedForStudentTrackerRequestError: (state) => {
      state.subjectsAdvancedForStudentTrackerLoading = false;
    },
    selectSubjectForStudentTracker: (state, action) => {
      state.selectedSubjectForStudentTracker = action.payload;
    },
    getSubjectAdvancedForAttendancePageRequestStart: (state) => {
      state.subjectsAdvancedForAttendancePageLoading = true;
    },
    getSubjectAdvancedForAttendancePageRequestSuccess: (state, action) => {
      state.subjectsAdvancedForAttendancePageLoading = false;
      state.subjectsAdvancedForAttendancePageData = action.payload;
    },
    getSubjectAdvancedForAttendancePageRequestError: (state) => {
      state.subjectsAdvancedForAttendancePageLoading = false;
    },
    selectSubjectForAttendancePage: (state, action) => {
      state.selectedSubjectForAttendancePage = action.payload;
    },
  },
});

export default subjectsAdvancedSlice.reducer;
export const { selectSubjectForAttendancePage, getSubjectAdvancedForAttendancePageRequestError, getSubjectAdvancedForAttendancePageRequestStart, getSubjectAdvancedForAttendancePageRequestSuccess, selectSubjectForStudentTracker,getSubjectAdvancedForStudentTrackerRequestError, getSubjectAdvancedForStudentTrackerRequestStart, getSubjectAdvancedForStudentTrackerRequestSuccess } = subjectsAdvancedSlice.actions;
