import { Button, message, Popconfirm, Modal, Space } from 'antd';
import 'antd/dist/antd.css';
import {studentsPageValues} from "../../consts/pages/students";
// utility functions

export const loadingShow = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

export const convertStatus = s => {
  switch (s) {
    case '1':
      return 'Enquiry';
    case '2':
      return 'Invoice Sent';
    case '3':
      return 'Assessment';
    case '4':
      return 'Offer';
    case '5':
      return 'Registered';
    case '6':
      return 'Archive';
    case '7':
      return 'Contacted';
    case '10':
      return 'Intro Email Sent';
    case '11':
      return 'Follow Up Email';
    case '12':
      return 'Call Scheduled';
    case '14':
      return 'Snoozed';
    case '15':
      return 'Returned';
    case '16':
      return 'Open Event';
    case '23':
      return 'EYFS';
    case '24':
      return 'Ukraine';
    case '25':
      return 'Called No Response';
    case '26':
      return 'EYFS call 1-to-1';
    case '27':
      return 'Business Partnership';
    default:
      return 'Enquiry';
  }
};

export const convertStudentStatus = s => {
  switch (s) {
    case 'S':
      return 'Active';
    case 'N':
      return 'Not Active';
    default:
      return 'Not Active';
  }
};

export const convertAttendanceStatus = s => {
  switch (s) {
    case 1:
      return 'Present';
    case 2:
      return 'Late';
    case 3:
      return 'Absent';
    case 4:
      return 'Opt out';
    case 8:
      return 'Not started';
    case 9:
      return 'Authorised Absence';
    case 10:
      return 'Sick';
    case 11:
      return 'Approved Educational Activity';
    default:
      return 'Present';
  }
};

// 24	Ukraine	2	67,162,188
// 25	Called No Response	1	168,104,197
// 26	EYFS call 1-to-1	605	86,169,111
// 27	Business Partnership	13	250,5,200
// 15	Returned	700	186,70,171
// 16	Open Event	5	107,148,115
// 14	Snoozed	599	255,192,0
// 23	EYFS	3	147,180,251
// 11	Follow Up Email	10	174,174,174
// 12	Call Scheduled	101	0,176,240
// 10	Intro Email Sent	4	248,196,246
// 1	Enquiry	1	249,53,85
// 2	Invoice Sent	200	138,228,199
// 3	Assessment	300	50,205,63
// 4	Offer	400	0,176,80
// 5	Registered	500	194,127,225
// 6	Archive	600	79,79,79
// 7	Contacted	100	249,250,144

// export const convertColor = (status) => {
//   switch (status) {
//     case '1':
//       return '#f450a8'
//     case '2':
//       return 'danger'
//     case '3':
//       return 'success'
//     case '4':
//       return 'warning'
//     case '5':
//       return 'warning'
//     case '6':
//       return 'warning'
//     default:
//       return '#fff'
//   }
// }

export const convertDate = timestamp => {
  // const date = new Date(timestamp)
  // const month = date.getMonth() + 1
  // const day = date.getDate()
  // const year = date.getFullYear()
  // return `${day}/${month}/${year}`
  const dateMonthYear = timestamp.split(' ')[0];
  return dateMonthYear;
};

export const convertDate5 = timestamp => {
  // const date = new Date(timestamp)
  // const month = date.getMonth() + 1
  // const day = date.getDate()
  // const year = date.getFullYear()
  // return `${day}/${month}/${year}`
  const dateMonthYear = timestamp.split(' ')[0];
  const yearMonthDate = dateMonthYear.split('/').reverse().join('');
  return yearMonthDate;
};

export const convertDate2 = date => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  if (!!Number(year) && !!Number(month) && !!Number(day)) {
    return `${year}/${month}/${day}`;
  }
  return '';
};

export const convertDate7 = date => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(9, 11);
  return `${day}/${month}/${year}`;
};

export const convertDate3 = timestamp => {
  const date = new Date(timestamp);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const newMonth = month.toString().padStart(2, '0');
  const newDay = day.toString().padStart(2, '0');
  return `${year}${newMonth}${newDay}`;
};

export const convertDate6 = date => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  return `${year}${month}${day}`;
};

export const convertDate4 = date => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  return day && month && year ? `${day}/${month}/${year}` : '';
};

export const getBadge = status => {
  switch (status) {
    case 'Enquiry':
      return 'rgb(249,53,85)';
    case 'Invoice Sent':
      return 'rgb(138,228,199)';
    case 'Assessment':
      return 'rgb(50,205,63)';
    case 'Offer':
      return 'rgb(0,176,80)';
    case 'Registered':
      return 'rgb(194,127,225)';
    case 'Archive':
      return 'rgb(79,79,79)';
    case 'Contacted':
      return 'rgb(2249,250,144)';
    case 'Intro Email Sent':
      return 'rgb(248,196,246)';
    case 'Follow Up Email':
      return 'rgb(174,174,174)';
    case 'Call Scheduled':
      return 'rgb(0,176,240)';
    case 'Snoozed':
      return 'rgb(255,192,0)';
    case 'Returned':
      return 'rgb(186,70,171)';
    case 'Open Event':
      return 'rgb(107,148,115)';
    case 'EYFS':
      return 'rgb(147,180,251)';
    case 'Ukraine':
      return 'rgb(67,162,188)';
    case 'Called No Response':
      return 'rgb(168,104,197)';
    case 'EYFS call 1-to-1':
      return 'rgb(147,180,251)';
    case 'Business Partnership':
      return 'rgb(250,5,200)';
    default:
      return 'rgb(249,53,85)';
  }
};

export const getBadgeStudent = status => {
  switch (status) {
    case studentsPageValues.activeStudentStatus:
      return 'rgb(127,194,255)';
    case  studentsPageValues.archivedStudentStatus:
      return 'rgb(255,142,136)';
    default:
      return 'rgb(255,142,136)';
  }
};

export const getBadgeTransparency = status => {
  switch (status) {
    case 'Enquiry':
      return 'rgb(249,53,85,.2)';
    case 'Invoice Sent':
      return 'rgb(138,228,199,.2)';
    case 'Assessment':
      return 'rgb(50,205,63,.2)';
    case 'Offer':
      return 'rgb(0,176,80,.2)';
    case 'Registered':
      return 'rgb(194,127,225,.2)';
    case 'Archive':
      return 'rgb(79,79,79,.2)';
    case 'Contacted':
      return 'rgb(2249,250,144,.2)';
    case 'Intro Email Sent':
      return 'rgb(248,196,246,.2)';
    case 'Follow Up Email':
      return 'rgb(174,174,174,.2)';
    case 'Call Scheduled':
      return 'rgb(0,176,240,.2)';
    case 'Snoozed':
      return 'rgb(255,192,0,.2)';
    case 'Returned':
      return 'rgb(186,70,171,.2)';
    case 'Open Event':
      return 'rgb(107,148,115,.2)';
    case 'EYFS':
      return 'rgb(147,180,251,.2)';
    case 'Ukraine':
      return 'rgb(67,162,188,.2)';
    case 'Called No Response':
      return 'rgb(168,104,197,.2)';
    case 'EYFS call 1-to-1':
      return 'rgb(147,180,251,.2)';
    case 'Business Partnership':
      return 'rgb(250,5,200,.2)';
    default:
      return 'rgb(249,53,85,.2)';
  }
};

export const getBadgeTransparencyStudent = status => {
  switch (status) {
    case studentsPageValues.activeStudentStatus:
      return 'rgb(127,194,255,.2)';
    case studentsPageValues.archivedStudentStatus:
      return 'rgb(1255,142,136,.2)';
    default:
      return 'rgb(1255,142,136,.2)';
  }
};

export const successMessage = (success, handleOk) => {
  if (success) {
    Modal.success({
      onOk: handleOk || (() => {}),
      content: success || 'Success!',
      zIndex: 9999,
      style: { marginTop: '40px' },
    });
  }
};

export const errorMessage = error => {
  if (error) {
    Modal.error({
      content: error,
      zIndex: 9999,
      style: { marginTop: '40px' },
    });
  }
};
