import { LOGIN_API_URL, USER_DATA, USER_ROLES_TEACHER } from '../../consts';
import { attendancePageValues } from '../../consts/pages/attendance';
import {
  USER_LOGIN_REQUEST_START,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_REQUEST_ERROR,
  USER_LOGOUT,
} from '../constants/authConstants';
import axiosExt from '../utils/axios';

export const actionUserLogout = () => dispatch => {
  localStorage.removeItem(USER_DATA);
  localStorage.removeItem('persist:root');
  dispatch({ type: USER_LOGOUT });
  const token = window.gapi?.client?.getToken();
  if (token !== null) {
    window.google?.accounts?.oauth2?.revoke(token.access_token);
    window.gapi?.client?.setToken('');
    localStorage.removeItem('google_api_token');
  }
};
export const actionUserLogin = (data, navigateObj) => dispatch => {
  dispatch({ type: USER_LOGIN_REQUEST_START });
  axiosExt({
    method: 'POST',
    url: LOGIN_API_URL,
    data,
  })
    .then(res => {
      const { data: { Result: resultData } = {} } = res;
      if (resultData) {
        localStorage.setItem(USER_DATA, JSON.stringify(resultData));
        dispatch({
          type: USER_LOGIN_REQUEST_SUCCESS,
          payload: resultData,
        });
        navigateObj(resultData?.Type === USER_ROLES_TEACHER ? attendancePageValues.pageUrl : '/dashboard');
      } else {
        dispatch({
          type: USER_LOGIN_REQUEST_ERROR,
          payload: ['Invalid credentials'],
        });
      }
    })
    .catch(error =>
      dispatch({
        type: USER_LOGIN_REQUEST_ERROR,
        payload: [error.message],
      }),
    );
};
