import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teacherSubjectWithGroupsArray: [],
  lastDataLoadedTime: 0,
};

export const teachersManagementSlice = createSlice({
  initialState,
  name: 'teachersManagement',
  reducers: {
    getTeacherSubjectsWithGroupsRequestStart: state => {
      state.teacherSubjectWithGroupsArray = [];
      state.lastDataLoadedTime = 0;
    },
    getTeacherSubjectsWithGroupsRequestSuccess: (state, action) => {
      state.teacherSubjectWithGroupsArray = action.payload;
      state.lastDataLoadedTime = new Date().getTime();
    },
    getTeacherSubjectsWithGroupsRequestError: state => {
      state.teacherSubjectWithGroupsArray = [];
      state.lastDataLoadedTime = new Date().getTime();
    },
  },
});

export default teachersManagementSlice.reducer;
export const {
  getTeacherSubjectsWithGroupsRequestStart,
  getTeacherSubjectsWithGroupsRequestSuccess,
  getTeacherSubjectsWithGroupsRequestError,
} = teachersManagementSlice.actions;
