import { Modal } from 'antd';
import React from 'react';

export const recordLastError = (currentErrors, newErrors) => {
  let finalErrors = [...currentErrors];
  newErrors.forEach(error => {
    const errorWithTime = `[${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}] ${error}`;
    finalErrors =
      finalErrors && finalErrors.length > 2
        ? [errorWithTime, ...[...finalErrors].slice(0, -1)]
        : [errorWithTime, ...finalErrors];
  });
  return finalErrors;
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};

export const parseFirstName = name => {
  return name?.split(' ')[0];
};

export const parseLastName = name => {
  return name?.includes(' ') ? name?.substring(name.indexOf(' ') + 1) : '';
};

export const confirmModal = ({ text, onOk }) =>
  Modal.confirm({
    content: <div>{text}</div>,
    zIndex: 9999,
    style: { marginTop: '40px' },
    onOk,
  });

export const generateFileWithUuid = data => ({ filesData: data, filesID: uuidv4() });
export const getUniqueArray = (inputArray) => {
  return inputArray?.length ? [...new Set(inputArray)] : [];
};