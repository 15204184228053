import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  goalsObjectivesGroupsArray: [],
  goalsObjectivesGroupsHashList: {},
  getGoalsObjectivesGroupsListLoading: false,
  goalsIndependentDataHashList: {},
  goalsIndependentLoadingHashList: {},
  objectivesIndependentDataHashList: {},
  objectivesIndependentLoadingHashList: {},
  selectedGoalsObjectivesGroup: {},
};

export const createCombinedGoalsIndependentID = ({ SubjectID, GoalsObjectivesGroupID }) =>
  `${SubjectID}${GoalsObjectivesGroupID}`;
export const createCombinedObjectivesIndependentID = ({ SubjectID, GoalsObjectivesGroupID, GoalID }) =>
  `${SubjectID}${GoalsObjectivesGroupID}${GoalID}`;

export const getGoalsObjectivesGroupsSlice = createSlice({
  initialState,
  name: 'goalsObjectivesGroupsSlice',
  reducers: {
    getGoalsObjectivesGroupsRequestStart: state => {
      state.getGoalsObjectivesGroupsListLoading = true;
    },
    getGoalsObjectivesGroupsRequestSuccess: (state, action) => {
      const data = action.payload;
      const newGoalsObjectivesGroupsListHashList = {};
      data.forEach(goalObjectiveGroup => {
        newGoalsObjectivesGroupsListHashList[goalObjectiveGroup.ID] = goalObjectiveGroup;
      });
      state.goalsObjectivesGroupsHashList = newGoalsObjectivesGroupsListHashList;
      state.goalsObjectivesGroupsArray = data;
      state.getGoalsObjectivesGroupsListLoading = false;
    },
    getGoalsObjectivesGroupsRequestError: state => {
      state.getGoalsObjectivesGroupsListLoading = false;
    },
    selectGoalsObjectivesGroup: (state, action) => {
      state.selectedGoalsObjectivesGroup = action.payload || {};
      state.objectivesHashList = {};
    },
    getIndependentGoalsRequestStart: (state, action) => {
      const { meta } = action.payload;
      const combinedID = createCombinedGoalsIndependentID(meta);
      state.goalsIndependentLoadingHashList = { ...state.goalsIndependentLoadingHashList, [combinedID]: true };
    },
    getIndependentGoalsRequestSuccess: (state, action) => {
      const { meta, goalsData, objectivesData } = action.payload;
      const combinedID = createCombinedGoalsIndependentID(meta);
      state.goalsIndependentDataHashList = { ...state.goalsIndependentDataHashList, [combinedID]: goalsData };
      state.goalsIndependentLoadingHashList = { ...state.goalsIndependentLoadingHashList, [combinedID]: false };
      state.objectivesHashList = objectivesData;
    },
    getIndependentGoalsRequestError: (state, action) => {
      const { meta } = action.payload;
      const combinedID = createCombinedGoalsIndependentID(meta);
      state.goalsIndependentLoadingHashList = { ...state.goalsIndependentLoadingHashList, [combinedID]: false };
    },
    getIndependentObjectivesRequestStart: (state, action) => {
      const { meta } = action.payload;
      const combinedID = createCombinedObjectivesIndependentID(meta);
      state.objectivesIndependentLoadingHashList = {
        ...state.objectivesIndependentLoadingHashList,
        [combinedID]: true,
      };
    },
    getIndependentObjectivesRequestSuccess: (state, action) => {
      const { meta, data } = action.payload;
      const combinedID = createCombinedObjectivesIndependentID(meta);
      state.objectivesIndependentDataHashList = { ...state.objectivesIndependentDataHashList, [combinedID]: data };
      state.objectivesIndependentLoadingHashList = {
        ...state.objectivesIndependentLoadingHashList,
        [combinedID]: false,
      };
    },
    getIndependentObjectivesRequestError: (state, action) => {
      const { meta } = action.payload;
      const combinedID = createCombinedObjectivesIndependentID(meta);
      state.objectivesIndependentLoadingHashList = {
        ...state.objectivesIndependentLoadingHashList,
        [combinedID]: false,
      };
    },
  },
});

export default getGoalsObjectivesGroupsSlice.reducer;
export const {
  getIndependentObjectivesRequestStart,
  getIndependentObjectivesRequestSuccess,
  getIndependentObjectivesRequestError,
  getIndependentGoalsRequestError,
  getIndependentGoalsRequestStart,
  getIndependentGoalsRequestSuccess,
  selectGoalsObjectivesGroup,
  getGoalsObjectivesGroupsRequestError,
  getGoalsObjectivesGroupsRequestStart,
  getGoalsObjectivesGroupsRequestSuccess,
} = getGoalsObjectivesGroupsSlice.actions;
