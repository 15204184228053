import {
  ADMISSION_LIST_REQUEST,
  ADMISSION_LIST_SUCCESS,
  ADMISSION_LIST_FAIL,
  ADMISSION_LIST_STATUS_CHANGE_REQUEST,
  ADMISSION_LIST_STATUS_CHANGE_SUCCESS,
  ADMISSION_LIST_STATUS_CHANGE_FAIL,
  ADMISSION_LIST_STATUS_CHANGE2_REQUEST,
  ADMISSION_LIST_STATUS_CHANGE2_SUCCESS,
  ADMISSION_LIST_STATUS_CHANGE2_FAIL,
  ADMISSION_LIST_DETAIL_REQUEST,
  ADMISSION_LIST_DETAIL_SUCCESS,
  ADMISSION_LIST_DETAIL_FAIL,
  ADMISSION_LIST_STUDENTS_VISIBLE_REQUEST,
  ADMISSION_LIST_STUDENTS_VISIBLE_SUCCESS,
  ADMISSION_LIST_STUDENTS_VISIBLE_FAIL,
  ADMISSION_LIST_STUDENTS_ADD_REQUEST,
  ADMISSION_LIST_STUDENTS_ADD_SUCCESS,
  ADMISSION_LIST_STUDENTS_ADD_FAIL,
  ADMISSION_LIST_STUDENTS_SAVE_REQUEST,
  ADMISSION_LIST_STUDENTS_SAVE_SUCCESS,
  ADMISSION_LIST_STUDENTS_SAVE_FAIL,
  ADMISSION_LIST_STUDENTS_DELETE_REQUEST,
  ADMISSION_LIST_STUDENTS_DELETE_SUCCESS,
  ADMISSION_LIST_STUDENTS_DELETE_FAIL,
  ADMISSION_LIST_DETAIL_SAVE_REQUEST,
  ADMISSION_LIST_DETAIL_SAVE_SUCCESS,
  ADMISSION_LIST_DETAIL_SAVE_FAIL,
  ADMISSION_LIST_NOTE_DELETE_REQUEST,
  ADMISSION_LIST_NOTE_DELETE_SUCCESS,
  ADMISSION_LIST_NOTE_DELETE_FAIL,
  ADMISSION_LIST_NOTE_ADD_REQUEST,
  ADMISSION_LIST_NOTE_ADD_SUCCESS,
  ADMISSION_LIST_NOTE_ADD_FAIL,
  GET_ADMISSIONS_ACTIONS_CODES_REQUEST_SUCCESS,
  GET_ADMISSIONS_YEAR_GROUPS_REQUEST_SUCCESS,
  GET_ADMISSIONS_ACADEMIC_YEARS_REQUEST_SUCCESS,
  GET_ADMISSIONS_TERMS_OF_ENTRY_REQUEST_SUCCESS,
  RESET_CURRENT_ADMISSION,
} from '../constants/admissionListConstants';
import { getBadge } from '../utils';

const initialState = {
  loading: false,
  admissions: [],
  error: '',
  success: '',
  currentAdmissionDetails: {
    id: null,
    firstParentDetails: {},
    secondParentDetails: {},
    otherDetailsForm: '',
  },
  students: [],
  notes: [],
  actionCodesArray: [],
  actionCodesHashList: {},
  yearGroups: [],
};

export const admissionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CURRENT_ADMISSION:
      return {
        ...state,
        currentAdmissionDetails: initialState.currentAdmissionDetails,
        students: initialState.students,
        notes: initialState.notes,
        error: '',
        success: '',
        lastDataUpdateTime: 0,
        admissionDetailError: '',
      };
    case ADMISSION_LIST_REQUEST:
      return { ...state, loading: true, admissions: [], error: '' };

    case ADMISSION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        admissions: action.payload.snoozeFirstResults,
        rawAdmissions: action.payload.rawResults,
        admissionsCount: action.payload.admissionsCount,
      };

    case ADMISSION_LIST_FAIL:
      return { ...state, loading: false, admissionsListError: action.payload, admissionsCount: 0 };

    case ADMISSION_LIST_STATUS_CHANGE_REQUEST:
      return { ...state, status_loading: true };
    case ADMISSION_LIST_STATUS_CHANGE_SUCCESS:
      const { itemID, value } = action.payload;
      const oldAdmissions = [...state.admissions];
      const oldRawAdmissions = [...state.rawAdmissions];
      for (let i = 0; i < oldAdmissions.length; i++) {
        if (Number(oldAdmissions[i].id) === Number(itemID)) {
          oldAdmissions[i].status = value;
          oldAdmissions[i]._props.color = getBadge(value);
        }
      }
      for (let i = 0; i < oldRawAdmissions.length; i++) {
        if (Number(oldRawAdmissions[i].id) === Number(itemID)) {
          oldRawAdmissions[i].Action = value;
        }
      }
      return { ...state, status_loading: false, admissions: oldAdmissions };
    case ADMISSION_LIST_STATUS_CHANGE_FAIL:
      return { ...state, status_loading: false, error: action.payload };

    case ADMISSION_LIST_STATUS_CHANGE2_REQUEST:
      return { ...state, status_loading: true };
    case ADMISSION_LIST_STATUS_CHANGE2_SUCCESS:
      const oldCurrentAdmission = { ...state.currentAdmissionDetails };
      oldCurrentAdmission.firstParentDetails.status = action.payload;
      return {
        ...state,
        status_loading: false,
        currentAdmissionDetails: oldCurrentAdmission,
      };
    case ADMISSION_LIST_STATUS_CHANGE2_FAIL:
      return { ...state, status_loading: false, error: action.payload };

    case ADMISSION_LIST_DETAIL_REQUEST:
      return { ...state, loading: true, admissionDetailError: '' };
    case ADMISSION_LIST_DETAIL_SUCCESS:
      const { shouldUpdateAfterAddNewItems } = action.payload;
      const newStudents = action.payload.STUDENT_DETAILS || [];
      const resultStudents = [...state.students].filter(student => student.ID);
      newStudents.forEach(student => {
        if (!resultStudents.find(currentStudent => Number(currentStudent.ID) === Number(student.ID))) {
          resultStudents.push(student);
        }
      });
      return {
        ...state,
        loading: false,
        currentAdmissionDetails: action.payload.PARENT_DETAILS,
        students: shouldUpdateAfterAddNewItems ? resultStudents : newStudents,
        lastDataUpdateTime: new Date().getTime(),
        notes: action.payload.NOTES,
      };
    case ADMISSION_LIST_DETAIL_FAIL:
      return { ...state, loading: false, admissionDetailError: action.payload };

    case ADMISSION_LIST_STUDENTS_VISIBLE_REQUEST:
      return { ...state, loading: true };
    case ADMISSION_LIST_STUDENTS_VISIBLE_SUCCESS:
      const students = [...state.students];
      const index = action.payload;
      students[index].isStudentVisible = !students[index].isStudentVisible;
      return { ...state, loading: false, students };
    case ADMISSION_LIST_STUDENTS_VISIBLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADMISSION_LIST_STUDENTS_ADD_REQUEST:
      return { ...state, loading: true };
    case ADMISSION_LIST_STUDENTS_ADD_SUCCESS:
      const i = state.students.length + 1;
      const studentsN = [...state.students];
      const closedStudents = studentsN.map(student => {
        student.isStudentVisible = false;
        return student;
      });

      const newStudent = {
        studentDetails: { id: 0 },
        otherDetails: '',
        emergencyContact: {},
        additionalInfo: {},
        documents: {},
        isStudentVisible: true,
      };

      return {
        ...state,
        loading: false,
        students: [...closedStudents, newStudent],
      };
    case ADMISSION_LIST_STUDENTS_ADD_FAIL:
      return { ...state, loading: false, error: action.payload };

    case ADMISSION_LIST_STUDENTS_DELETE_REQUEST:
      return { ...state, loading: true };
    case ADMISSION_LIST_STUDENTS_DELETE_SUCCESS:
      if (state.students.length > 1) {
        const students = [...state.students];
        const index = action.payload;
        students.splice(index, 1);
        if (students[0]) {
          students[0].isStudentVisible = true;
        }
        return { ...state, loading: false, students };
      }
      return { ...state, loading: false, students: [] };

    case ADMISSION_LIST_STUDENTS_DELETE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case ADMISSION_LIST_DETAIL_SAVE_REQUEST:
      return { ...state, loading: true };
    case ADMISSION_LIST_DETAIL_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAdmissionDetails: action.payload.PARENT_DETAILS,
        success: action.payload.SUCCESS,
      };
    case ADMISSION_LIST_DETAIL_SAVE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case ADMISSION_LIST_STUDENTS_SAVE_SUCCESS: {
      const students = [...state.students];
      const { index, updatedStudentData } = action.payload;
      students[index] = { ...students[index], ...updatedStudentData };
      return {
        ...state,
        loading: false,
        success: action.payload.SUCCESS,
        students,
      };
    }
    case ADMISSION_LIST_STUDENTS_SAVE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case ADMISSION_LIST_NOTE_DELETE_REQUEST:
      return { ...state, loading: true };
    case ADMISSION_LIST_NOTE_DELETE_SUCCESS:
      const notes = [...state.notes];
      const idx = action.payload;
      notes.splice(idx, 1);
      return { ...state, loading: false, notes };
    case ADMISSION_LIST_NOTE_DELETE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case ADMISSION_LIST_NOTE_ADD_REQUEST:
      return { ...state, loading: true };
    case ADMISSION_LIST_NOTE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: [action.payload, ...state.notes],
      };
    case ADMISSION_LIST_NOTE_ADD_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_ADMISSIONS_ACTIONS_CODES_REQUEST_SUCCESS:
      const actionCodesArray = action.payload || [];
      const actionCodesHashList = {};
      actionCodesArray.forEach(actionCode => {
        actionCodesHashList[actionCode.ID] = actionCode;
      });
      return { ...state, actionCodesArray, actionCodesHashList };
    case GET_ADMISSIONS_YEAR_GROUPS_REQUEST_SUCCESS:
      const yearGroupsArray = action.payload || [];
      const yearGroupsHashList = {};
      yearGroupsArray.forEach(actionCode => {
        yearGroupsHashList[actionCode.ID] = actionCode;
      });
      return { ...state, yearGroupsArray, yearGroupsHashList };
    case GET_ADMISSIONS_ACADEMIC_YEARS_REQUEST_SUCCESS:
      const academicYearsArray = action.payload || [];
      const academicYearsHashList = {};
      academicYearsArray.forEach(academicYear => {
        academicYearsHashList[academicYear.ID] = academicYear;
      });
      return { ...state, academicYearsArray, academicYearsHashList };
    default:
      return state;
  }
};
