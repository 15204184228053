import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  digitalNotebooksArray: [],
  isDigitalNotebooksListLoading: false,
  isAddUpdateDigitalNotebooksLoading: false,
  isGetDigitalNotebookRequestLoading: false,
  isAddUpdateDigitalNotebookRequestLoading: false,
  lastDataUpdateTime: 0,
  selectedDigitalNotebook: {},
};

export const digitalNotebookSlice = createSlice({
  initialState,
  name: 'digitalNotebook',
  reducers: {
    getDigitalNotebooksListStart: (state) => {
      state.digitalNotebooksArray = [];
      state.isDigitalNotebooksListLoading = true;
    },
    getDigitalNotebooksListSuccess: (state, action) => {
      state.digitalNotebooksArray = action.payload;
      state.isDigitalNotebooksListLoading = false;
      state.lastDataUpdateTime = new Date().getDate();
    },
    getDigitalNotebooksListError: (state) => {
      state.isDigitalNotebooksListLoading = false;
    },

    getDigitalNotebookStart: (state) => {
      state.isGetDigitalNotebookRequestLoading = true;
    },
    getDigitalNotebookSuccess: (state) => {
      state.isGetDigitalNotebookRequestLoading = false;
    },
    getDigitalNotebookError: (state) => {
      state.isGetDigitalNotebookRequestLoading = false;
    },

    addUpdateDigitalNotebookStart: (state) => {
      state.isAddUpdateDigitalNotebooksLoading = true;
    },

    addUpdateDigitalNotebookSuccess: (state, action) => {
      const { ID: updatedNotebookID } = action.payload;
      const newDigitalNotebooksArray = state.digitalNotebooksArray.map(notebookData => {
        const { ID } = notebookData;
        if (ID === updatedNotebookID) {
          return action.payload;
        }
        return notebookData;
      });

      if (!updatedNotebookID) {
        newDigitalNotebooksArray.push(action.payload);
      }
      state.digitalNotebooksArray = newDigitalNotebooksArray;
      state.isAddUpdateDigitalNotebooksLoading = false;
    },
    addUpdateDigitalNotebookError: (state) => {
      state.isAddUpdateDigitalNotebooksLoading = false;
    },
    selectDigitalNotebook: (state, action) => {
      state.selectedDigitalNotebook = action.payload;
    },
  },
});

export default digitalNotebookSlice.reducer;
export const {
  getDigitalNotebookError,
  getDigitalNotebookSuccess,
  getDigitalNotebookStart,
  addUpdateDigitalNotebookStart,
  addUpdateDigitalNotebookSuccess,
  addUpdateDigitalNotebookError,
  getDigitalNotebooksListStart,
  getDigitalNotebooksListSuccess,
  getDigitalNotebooksListError,
  selectDigitalNotebook,
} = digitalNotebookSlice.actions;
