import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  averageAttendancesData: {},
  averageAttendancesLoading: false,
  absentStudentAttendancesErrors: [],
  absentStudentAttendancesLoading: true,
};

export const dashboardDataSlice = createSlice({
  initialState,
  name: 'dashboardData',
  reducers: {
    getAverageAttendanceRequestStart: state => {
      state.averageAttendancesData = {};
      state.averageAttendancesLoading = true;
    },
    getAverageAttendanceRequestSuccess: (state, action) => {
      state.averageAttendancesData = action.payload;
      state.averageAttendancesLoading = false;
    },
    getAverageAttendanceRequestError: state => {
      state.averageAttendancesLoading = false;
      state.averageAttendancesData = {};
    },
  },
});

export default dashboardDataSlice.reducer;
export const {
  getAverageAttendanceRequestStart,
  getAverageAttendanceRequestSuccess,
  getAverageAttendanceRequestError,
} = dashboardDataSlice.actions;
