import { createSlice } from '@reduxjs/toolkit';

import { recordLastError } from '../../helpers';

const initialState = {
  lastGlobalErrors: [],
};

export const errorsSlice = createSlice({
  initialState,
  name: 'errors',
  reducers: {
    addGlobalErrors: (state, action) => {
      state.lastGlobalErrors = recordLastError(state.lastGlobalErrors, action.payload);
    },
    clearGlobalErrors: state => {
      state.lastGlobalErrors = [];
    },
  },
});

export default errorsSlice.reducer;
export const { addGlobalErrors, clearGlobalErrors } = errorsSlice.actions;
