import {
  SETTINGS_SET,
  SETTINGS_SHOW_HEADER,
} from '../constants/settingsConstants'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  unfoldable: false,
  showHeader: true,
}

export const settingsReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SETTINGS_SET:
      return { ...state, ...rest }
    case SETTINGS_SHOW_HEADER:
      return { ...state, ...rest }
    default:
      return state
  }
}
