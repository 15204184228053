import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosExt from "../utils/request";
import {DELETE_FILE_FROM_URL_API_URL} from "../../consts";

const initialState = {
  filesUploadedHashObject: {},
  filesUploadLoadingHashObject: {},
  filesUploadErrorsHashObject: {},
  foldersDownloadedHashObject: {},
  foldersDownloadLoadingHashObject: {},
  foldersDownloadErrorsHashObject: {},
  foldersHashObject: {},
  foldersIsLoading: false,
  foldersErrors: [],
};

export const actionDeleteFileFromUrl = createAsyncThunk('files/DeleteFileFromURL', async (URL) => {
  return await axiosExt({
    method: 'DELETE',
    url: DELETE_FILE_FROM_URL_API_URL,
    data: {URL},
    displaySuccessMessage: true,
  });
})

export const filesSlice = createSlice({
  initialState,
  name: 'files',
  extraReducers: builder => {
    builder.addCase(actionDeleteFileFromUrl.fulfilled, state => {
      state.lastFilesDeleteTime = new Date().getTime();
    });
  },
  reducers: {
    startUploadFiles: (state, action) => {
      state.filesUploadLoadingHashObject = { ...state.filesUploadLoadingHashObject, [action.payload]: true };
    },
    successUploadFiles: (state, action) => {
      const { meta: { FolderName } = {} } = action.payload;
      state.filesUploadLoadingHashObject = { ...state.filesUploadLoadingHashObject, [FolderName]: false };
      state.filesUploadedHashObject = { ...state.filesUploadedHashObject, [FolderName]: true };
    },
    failedUploadFiles: (state, action) => {
      const { data, meta: { FolderName } = {} } = action.payload;
      state.filesUploadLoadingHashObject = { ...state.filesUploadLoadingHashObject, [FolderName]: false };
      state.filesUploadErrorsHashObject = { ...state.filesUploadErrorsHashObject, [FolderName]: data };
    },
    startDownloadFolder: (state, action) => {
      state.foldersDownloadLoadingHashObject = { ...state.foldersDownloadLoadingHashObject, [action.payload]: true };
    },
    successDownloadFolder: (state, action) => {
      const { data, meta: { FolderName } = {} } = action.payload;
      state.foldersDownloadLoadingHashObject = { ...state.foldersDownloadLoadingHashObject, [FolderName]: false };
      state.foldersDownloadedHashObject = { ...state.foldersDownloadedHashObject, [FolderName]: data };
    },
    failedDownloadFolder: (state, action) => {
      const { data, meta: { FolderName } = {} } = action.payload;
      state.foldersDownloadLoadingHashObject = { ...state.foldersDownloadLoadingHashObject, [FolderName]: false };
      state.foldersDownloadErrorsHashObject = { ...state.foldersDownloadErrorsHashObject, [FolderName]: data };
    },
    getFoldersDataRequestStart: state => {
      state.foldersIsLoading = true;
      state.foldersErrors = [];
    },
    getFoldersDataRequestSuccess: (state, action) => {
      const foldersHashObject = {};
      const { data: foldersList = [] } = action.payload;
      foldersList.forEach(folderData => {
        foldersHashObject[folderData.Name] = folderData;
      });
      state.foldersHashObject = foldersHashObject;
      state.foldersIsLoading = false;
    },
    getFoldersDataRequestError: (state, action) => {
      state.foldersErrors = action.payload;
      state.foldersIsLoading = false;
    },
  },
});

export default filesSlice.reducer;
export const {
  getFoldersDataRequestSuccess,
  getFoldersDataRequestError,
  getFoldersDataRequestStart,
  startUploadFiles,
  successUploadFiles,
  failedUploadFiles,
  failedDownloadFolder,
  startDownloadFolder,
  successDownloadFolder,
} = filesSlice.actions;
