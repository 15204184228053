export const STUDENT_LIST_REQUEST = 'STUDENT_LIST_REQUEST'
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS'
export const STUDENT_LIST_FAIL = 'STUDENT_LIST_FAIL'

export const STUDENT_LIST_DETAIL_REQUEST = 'STUDENT_LIST_DETAIL_REQUEST'
export const RESET_CURRENT_STUDENT = 'RESET_CURRENT_STUDENT'

export const STUDENT_LIST_DETAIL_SUCCESS = 'STUDENT_LIST_DETAIL_SUCCESS'
export const STUDENT_LIST_DETAIL_FAIL = 'STUDENT_LIST_DETAIL_FAIL'
export const NOTES_REQUEST_SUCCESS = 'NOTES_REQUEST_SUCCESS'
export const STUDENT_DOCUMENT_TYPES_REQUEST_SUCCESS = 'STUDENT_DOCUMENT_TYPES_REQUEST_SUCCESS'

// for changing the status of any STUDENT on the table
export const STUDENT_LIST_STATUS_CHANGE_REQUEST =
  'STUDENT_LIST_STATUS_CHANGE_REQUEST'
export const STUDENT_LIST_STATUS_CHANGE_SUCCESS =
  'STUDENT_LIST_STATUS_CHANGE_SUCCESS'
export const STUDENT_LIST_STATUS_CHANGE_FAIL = 'STUDENT_LIST_STATUS_CHANGE_FAIL'

// for changing the status of the current global STUDENT detail
export const STUDENT_LIST_STATUS_CHANGE2_REQUEST =
  'STUDENT_LIST_STATUS_CHANGE2_REQUEST'
export const STUDENT_LIST_STATUS_CHANGE2_SUCCESS =
  'STUDENT_LIST_STATUS_CHANGE2_SUCCESS'
export const STUDENT_LIST_STATUS_CHANGE2_FAIL =
  'STUDENT_LIST_STATUS_CHANGE2_FAIL'

export const STUDENT_LIST_STUDENTS_DELETE_REQUEST =
  'STUDENT_LIST_STUDENTS_DELETE_REQUEST'
export const STUDENT_LIST_STUDENTS_DELETE_SUCCESS =
  'STUDENT_LIST_STUDENTS_DELETE_SUCCESS'
export const STUDENT_LIST_STUDENTS_DELETE_FAIL =
  'STUDENT_LIST_STUDENTS_DELETE_FAIL'

export const STUDENT_LIST_DETAIL_SAVE_REQUEST =
  'STUDENT_LIST_DETAIL_SAVE_REQUEST'
export const STUDENT_LIST_DETAIL_SAVE_SUCCESS =
  'STUDENT_LIST_DETAIL_SAVE_SUCCESS'
export const STUDENT_LIST_DETAIL_SAVE_FAIL = 'STUDENT_LIST_DETAIL_SAVE_FAIL'

export const STUDENT_LIST_NOTE_DELETE_REQUEST =
  'STUDENT_LIST_NOTE_DELETE_REQUEST'
export const STUDENT_LIST_NOTE_DELETE_SUCCESS =
  'STUDENT_LIST_NOTE_DELETE_SUCCESS'
export const STUDENT_LIST_NOTE_DELETE_FAIL = 'STUDENT_LIST_NOTE_DELETE_FAIL'

export const STUDENT_LIST_NOTE_ADD_REQUEST = 'STUDENT_LIST_NOTE_ADD_REQUEST'
export const STUDENT_LIST_NOTE_ADD_SUCCESS = 'STUDENT_LIST_NOTE_ADD_SUCCESS'
export const STUDENT_LIST_NOTE_ADD_FAIL = 'STUDENT_LIST_NOTE_ADD_FAIL'
