import {
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_SUCCESS,
  STUDENT_LIST_FAIL,
  STUDENT_LIST_STATUS_CHANGE_REQUEST,
  STUDENT_LIST_STATUS_CHANGE_SUCCESS,
  STUDENT_LIST_STATUS_CHANGE_FAIL,
  STUDENT_LIST_STATUS_CHANGE2_REQUEST,
  STUDENT_LIST_STATUS_CHANGE2_SUCCESS,
  STUDENT_LIST_STATUS_CHANGE2_FAIL,
  STUDENT_LIST_DETAIL_REQUEST,
  STUDENT_LIST_DETAIL_SUCCESS,
  STUDENT_LIST_DETAIL_FAIL,
  STUDENT_LIST_STUDENTS_DELETE_REQUEST,
  STUDENT_LIST_STUDENTS_DELETE_SUCCESS,
  STUDENT_LIST_STUDENTS_DELETE_FAIL,
  STUDENT_LIST_DETAIL_SAVE_REQUEST,
  STUDENT_LIST_DETAIL_SAVE_SUCCESS,
  STUDENT_LIST_DETAIL_SAVE_FAIL,
  STUDENT_LIST_NOTE_DELETE_REQUEST,
  STUDENT_LIST_NOTE_DELETE_SUCCESS,
  STUDENT_LIST_NOTE_DELETE_FAIL,
  STUDENT_LIST_NOTE_ADD_REQUEST,
  STUDENT_LIST_NOTE_ADD_SUCCESS,
  STUDENT_LIST_NOTE_ADD_FAIL,
  NOTES_REQUEST_SUCCESS,
  STUDENT_DOCUMENT_TYPES_REQUEST_SUCCESS,
  RESET_CURRENT_STUDENT,
} from '../constants/studentListConstants';
import { getBadge } from '../utils';

const initialState = {
  loading: false,
  students: [],
  error: '',
  currentStudentDetails: {
    id: null,
    studentDetails: {},
    parentDetails: {},
    otherSchoolsDetails: {},
    documents: {},
    reports: {},
    certificates: {},
  },
  notes: [],
  studentDocumentTypes: [],
};

export const studentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_LIST_REQUEST:
      return { ...state, loading: true, studentsListError: '' };

    case STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload.data,
        activeCount: state.activeCount || action.payload.activeCount,
        count: action.payload.count,
      };

    case STUDENT_LIST_FAIL:
      return { ...state, loading: false, studentsListError: action.payload };

    case STUDENT_LIST_STATUS_CHANGE_REQUEST:
      return { ...state, status_loading: true };
    case STUDENT_LIST_STATUS_CHANGE_SUCCESS:
      const { ID, Status } = action.payload;
      const oldStudents = [...state.students];
      for (let i = 0; i < oldStudents.length; i++) {
        if (oldStudents[i].id === ID) {
          oldStudents[i].status = Status;
          oldStudents[i]._props.color = getBadge(Status);
        }
      }
      return { ...state, status_loading: false, students: oldStudents };
    case STUDENT_LIST_STATUS_CHANGE_FAIL:
      return { ...state, status_loading: false, error: action.payload };

    case STUDENT_LIST_STATUS_CHANGE2_REQUEST:
      return { ...state, status_loading: true };
    case STUDENT_LIST_STATUS_CHANGE2_SUCCESS:
      const oldCurrentStudent = { ...state.currentStudentDetails };
      oldCurrentStudent.firstParentDetails.status = action.payload;
      return {
        ...state,
        status_loading: false,
        currentStudentDetails: oldCurrentStudent,
      };
    case STUDENT_LIST_STATUS_CHANGE2_FAIL:
      return { ...state, status_loading: false, error: action.payload };

    case STUDENT_LIST_DETAIL_REQUEST:
      return { ...state, loading: true, studentDetailsError: '' };
    case STUDENT_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        currentStudentDetails: action.payload.CURRENT_STUDENT_DETAILS,
      };
    case NOTES_REQUEST_SUCCESS:
      return {
        ...state,
        notes: action.payload,
      };
    case STUDENT_LIST_DETAIL_FAIL:
      return { ...state, loading: false, studentDetailsError: action.payload };

    case STUDENT_LIST_STUDENTS_DELETE_REQUEST:
      return { ...state, loading: true };
    case STUDENT_LIST_STUDENTS_DELETE_SUCCESS:
      if (state.students.length > 1) {
        const students = [...state.students];
        const index = action.payload;
        students.splice(index, 1);
        return { ...state, loading: false, students };
      }
      return { ...state, loading: false };
    case STUDENT_LIST_STUDENTS_DELETE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case STUDENT_LIST_DETAIL_SAVE_REQUEST:
      return { ...state, loading: true };
    case STUDENT_LIST_DETAIL_SAVE_SUCCESS:
      return { ...state, loading: false, currentStudentDetails: action.payload };
    case STUDENT_LIST_DETAIL_SAVE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case STUDENT_LIST_NOTE_DELETE_REQUEST:
      return { ...state, loading: true };
    case STUDENT_LIST_NOTE_DELETE_SUCCESS:
      const notes = [...state.notes];
      const idx = action.payload;
      notes.splice(idx, 1);
      return { ...state, loading: false, notes };
    case STUDENT_LIST_NOTE_DELETE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case STUDENT_LIST_NOTE_ADD_REQUEST:
      return { ...state, noteLoading: true };
    case STUDENT_LIST_NOTE_ADD_SUCCESS:
      return {
        ...state,
        noteLoading: false,
        notes: [action.payload, ...state.notes],
      };
    case STUDENT_LIST_NOTE_ADD_FAIL:
      return { ...state, noteLoading: false, error: action.payload };
    case STUDENT_DOCUMENT_TYPES_REQUEST_SUCCESS:
      return { ...state, studentDocumentTypes: action.payload };
    case RESET_CURRENT_STUDENT: {
      return initialState;
    }
    default:
      return state;
  }
};
