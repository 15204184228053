import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { studentsPageValues } from '../../consts/pages/students';
import { parsedToRawStudentData, rawToParsedStudentData } from '../../helpers/dataParsers';
import { selectStudentsListPage } from '../selectors/students';
import axiosExt from '../utils/request';

const initialState = {
  page: 1,
  count: undefined,
  studentsListArray: [],
  studentsListHashList: {},
  currentStudentData: {},
  studentsListIsLoading: false,
  studentItemDataIsLoading: false,
  activeCount: undefined,
  activeFilters: {
    dateFrom: '',
    dateTo: '',
  },
};

export const actionFetchStudentsList = createAsyncThunk('actionFetchStudentsList', async (requestData, thunkAPI) => {
  const state = thunkAPI.getState();
  const page = selectStudentsListPage(state);
  const response = await axiosExt({
    method: studentsPageValues.getListAPIData.method,
    url: studentsPageValues.getListAPIData.url,
    hideGlobalErrors: true,
    data: {
      Page: page,
      id: 0,
      TeacherID: 0,
      SubjectID: 0,
      GroupID: 0,
      Status: '',
      ...requestData,
    },
  });
  const {
    data: { Result = {} },
  } = response;
  const { ActiveStudent: activeCount, RecordsTotal: count, Result: studentsListArray = [] } = Result ?? {};
  const studentsListHashList = {};
  studentsListArray.forEach(student => {
    studentsListHashList[student.ID] = student;
  });
  return { count, studentsListHashList, studentsListArray, activeCount };
});

export const actionGetStudentItem = createAsyncThunk('actionGetStudentItem', async ID => {
  const response = await axiosExt({
    method: studentsPageValues.getItemAPIData.method,
    url: `${studentsPageValues.getItemAPIData.url}?ID=${ID}`,
  });

  const actualResultItem = response?.data?.Result || [];

  return rawToParsedStudentData(actualResultItem[0] || {});
});

export const actionCreateEditStudentItem = createAsyncThunk('actionCreateEditStudentItem', async (data = {}) => {
  const { data: { Result: { StudentID } = {} } = {} } = await axiosExt({
    method: studentsPageValues.updateItemAPIData.method,
    url: `${studentsPageValues.updateItemAPIData.url}/${data?.ID ? `?id=${data.ID}` : ''}`,
    data,
    displaySuccessMessage: true,
  });

  return rawToParsedStudentData({ ...data, ID: data.ID || StudentID });
});

export const studentsListSlice = createSlice({
  initialState,
  name: 'studentsList',
  extraReducers: builder => {
    builder.addCase(actionFetchStudentsList.pending, state => {
      state.studentsListIsLoading = true;
    });

    builder.addCase(actionFetchStudentsList.fulfilled, (state, action) => {
      const { count, studentsListHashList, studentsListArray, activeCount } = action.payload;
      state.studentsListIsLoading = false;
      state.count = count;
      state.studentsListHashList = studentsListHashList;
      state.studentsListArray = studentsListArray;
      state.activeCount = activeCount || state.activeCount;
    });
    builder.addCase(actionFetchStudentsList.rejected, state => {
      state.studentsListIsLoading = false;
    });
    builder.addMatcher(isAnyOf(actionGetStudentItem.pending, actionCreateEditStudentItem.pending), state => {
      state.studentItemDataIsLoading = true;
    });
    builder.addMatcher(
      isAnyOf(
        actionGetStudentItem.rejected,
        actionCreateEditStudentItem.rejected,
        actionCreateEditStudentItem.fulfilled,
      ),
      state => {
        state.studentItemDataIsLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(actionGetStudentItem.fulfilled, actionCreateEditStudentItem.fulfilled),
      (state, action) => {
        state.studentItemDataIsLoading = false;
        state.currentStudentData = {
          ...state.currentStudentData,
          ...action.payload,
          dataSnapshot: { ...state.currentStudentData.dataSnapshot, ...action.payload },
        };
      },
    );
  },
  reducers: {
    resetStudentsPageState: () => initialState,
    resetCurrentStudentData: state => {
      state.currentStudentData = {};
    },
    changeCurrentStudentData: (state, action) => {
      state.currentStudentData = { ...state.currentStudentData, ...action.payload };
    },
    changeActiveFilter: (state, action) => {
      state.activeFilters = { ...state.activeFilters, ...action.payload };
    },
  },
});

export default studentsListSlice.reducer;

export const { changeCurrentStudentData, resetStudentsPageState, resetCurrentStudentData, changeActiveFilter } =
  studentsListSlice.actions;
