import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { CREATE_UPDATE_USER_ITEM_API_URL, GET_USER_ITEM_API_URL, GET_USERS_LIST_API_URL } from '../../consts';
import axiosExt from '../utils/request';

const initialState = {
  page: 1,
  count: undefined,
  usersListArray: [],
  usersListHashList: {},
  currentUserData: {},
  usersListIsLoading: false,
  userItemDataIsLoading: false,
  activeFilters: {
    dateFrom: '',
    dateTo: '',
  },
};

export const actionFetchUsersList = createAsyncThunk('actionFetchUsersList', async (requestData, thunkAPI) => {
  const state = thunkAPI.getState();
  const { page } = state.users;
  const response = await axiosExt({
    method: 'POST',
    url: GET_USERS_LIST_API_URL,
    data: {
      Page: page,
      ...requestData,
    },
  });
  const {
    data: { Result: { RecordsTotal: count, Result: usersListArray = [] } = ({} = {}) },
  } = response;
  const usersListHashList = {};
  usersListArray.forEach(user => {
    usersListHashList[user.ID] = user;
  });
  return { count, usersListHashList, usersListArray };
});

export const actionGetUserItem = createAsyncThunk('actionGetUserItem', async ID => {
  const response = await axiosExt({
    method: 'POST',
    url: GET_USER_ITEM_API_URL,
    data: {
      ID,
    },
  });
  return response?.data?.Result || {};
});

export const actionCreateEditUserItem = createAsyncThunk(
  'actionCreateEditUserItem',
  async (data, { getState, rejectWithValue }) => {
    await axiosExt({
      method: 'POST',
      url: CREATE_UPDATE_USER_ITEM_API_URL,
      data,
      displaySuccessMessage: true,
    });
    return { ...getState().users.currentUserData, ...data };
  },
);

export const actionDeleteUserItem = createAsyncThunk(
  'actionDeleteUserItem',
  async (data, { getState, rejectWithValue }) => {
    await axiosExt({
      method: 'DELETE',
      url: CREATE_UPDATE_USER_ITEM_API_URL,
      data,
      displaySuccessMessage: true,
    });
  },
);

export const usersListSlice = createSlice({
  initialState,
  name: 'usersList',
  extraReducers: builder => {
    builder.addCase(actionFetchUsersList.pending, state => {
      state.usersListIsLoading = true;
    });

    builder.addCase(actionFetchUsersList.fulfilled, (state, action) => {
      const { count, usersListHashList, usersListArray } = action.payload;
      state.usersListIsLoading = false;
      state.count = count;
      state.usersListHashList = usersListHashList;
      state.usersListArray = usersListArray;
    });
    builder.addCase(actionFetchUsersList.rejected, state => {
      state.usersListIsLoading = false;
    });
    builder.addMatcher(isAnyOf(actionGetUserItem.pending, actionCreateEditUserItem.pending), state => {
      state.userItemDataIsLoading = true;
    });
    builder.addMatcher(isAnyOf(actionGetUserItem.rejected, actionCreateEditUserItem.rejected), state => {
      state.userItemDataIsLoading = false;
    });
    builder.addMatcher(isAnyOf(actionGetUserItem.fulfilled, actionCreateEditUserItem.fulfilled), (state, action) => {
      state.userItemDataIsLoading = false;
      state.currentUserData = {
        ...state.currentUserData,
        ...action.payload,
        dataSnapshot: { ...state.currentUserData.dataSnapshot, ...action.payload },
      };
    });
  },
  reducers: {
    resetUsersPageState: () => initialState,
    resetCurrentUserData: state => {
      state.currentUserData = {};
    },
    changeCurrentUserData: (state, action) => {
      state.currentUserData = { ...state.currentUserData, ...action.payload };
    },
    changeActiveFilter: (state, action) => {
      state.activeFilters = { ...state.activeFilters, ...action.payload };
    },
  },
});

export default usersListSlice.reducer;

export const { changeCurrentUserData, resetUsersPageState, resetCurrentUserData, changeActiveFilter } =
  usersListSlice.actions;
