import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queryReportsDataArray: [],
  queryReportsDataHashList: [],
  isQueryReportsListLoading: false,
  isQueryReportsDataLoading: false,
};

export const queryReportsSlice = createSlice({
  initialState,
  name: 'queryReports',
  reducers: {
    getQueryReportsListRequestStart: state => {
      state.isQueryReportsListLoading = true;
    },
    getQueryReportsListRequestSuccess: (state, action) => {
      state.isQueryReportsListLoading = false;
      const newQueryReportsDataHashList = {};
      action.payload.forEach(queryReportItem => {
        newQueryReportsDataHashList[queryReportItem.ID] = queryReportItem;
      });
      state.queryReportsDataArray = action.payload;
      state.queryReportsDataHashList = newQueryReportsDataHashList;
    },
    getQueryReportsListRequestError: state => {
      state.isQueryReportsListLoading = false;
    },
    getQueryReportsDataRequestStart: state => {
      state.isQueryReportsDataLoading = true;
    },
    getQueryReportsDataRequestSuccess: (state, action) => {
      state.isQueryReportsDataLoading = false;
      const { data, meta: { ID: QueryReportID } = {} } = action.payload;
      const currentQueryReportsDataHashList = { ...state.queryReportsDataHashList };
      state.queryReportsDataHashList = {
        ...currentQueryReportsDataHashList,
        [QueryReportID]: { ...currentQueryReportsDataHashList[QueryReportID], data },
      };
    },
    getQueryReportsDataRequestError: state => {
      state.isQueryReportsDataLoading = false;
    },
    getQueryReportsXlsPdfRequestStart: state => {
      state.isQueryReportsDataLoading = true;
    },
    getQueryReportsXlsPdfRequestSuccess: (state, action) => {
      state.isQueryReportsDataLoading = false;
      const { data, meta: { ID: QueryReportID } = {} } = action.payload;
      const currentQueryReportsDataHashList = { ...state.queryReportsDataHashList };
      state.queryReportsDataHashList = {
        ...currentQueryReportsDataHashList,
        [QueryReportID]: { ...currentQueryReportsDataHashList[QueryReportID], xlsPdf: data },
      };
    },
    getQueryReportsXlsPdfRequestError: state => {
      state.isQueryReportsDataLoading = false;
    },
  },
});

export default queryReportsSlice.reducer;
export const {
  getQueryReportsListRequestStart,
  getQueryReportsListRequestSuccess,
  getQueryReportsDataRequestError,
  getQueryReportsDataRequestStart,
  getQueryReportsDataRequestSuccess,
  getQueryReportsListRequestError,
  getQueryReportsXlsPdfRequestStart,
  getQueryReportsXlsPdfRequestSuccess,
  getQueryReportsXlsPdfRequestError,
} = queryReportsSlice.actions;
