import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { USER_DATA } from '../consts';

import { USER_LOGOUT } from './constants/authConstants';
import { admissionListReducer } from './reducers/admissionListReducer';
import { authReducer } from './reducers/authReducer';
import { settingsReducer } from './reducers/settingsReducer';
import { studentListReducer } from './reducers/studentListReducer';
import attendancesListSlice from './slices/attendance';
import candidatesListSlice from './slices/candidates';
import commonSlice from './slices/common';
import dashboardDataSlice from './slices/dashboardData';
import digitalNotebookSlice from './slices/digitalNotebook';
import emailTemplatesSlice from './slices/emailTemplates';
import errorsSlice from './slices/errors';
import filesSlice from './slices/files';
import goalsPageReducer from './slices/goalsAndObjectives';
import goalsObjectivesGroupsSlice from './slices/goalsObjectivesGroups';
import lateRegistrationsListSlice from './slices/lateRegistrations';
import queryReportsSlice from './slices/queryReports';
import relativeSelectPickersSlice from './slices/relativeSelectPickers';
import reportsSlice from './slices/reports';
import scheduleSlice from './slices/schedule';
import studentGroupsManagementSlice from './slices/studentGroupsManagement';
import students from './slices/students';
import studentTrackerSlice from './slices/studentTracker';
import subjectsAdvancedSlice from './slices/subjectsAdvanced';
import subjectsManagementSlice from './slices/subjectsManagement';
import teachersManagementSlice from './slices/teachersManagement';
import termDatesManagementSlice from './slices/termDatesManagement';
import usersSlice from './slices/users';

const reducer = combineReducers({
  studentList: studentListReducer,
  admissionList: admissionListReducer,
  settings: settingsReducer,
  auth: authReducer,
  students,
  candidates: candidatesListSlice,
  lateRegistrations: lateRegistrationsListSlice,
  attendances: attendancesListSlice,
  users: usersSlice,
  studentTracker: studentTrackerSlice,
  errors: errorsSlice,
  schedule: scheduleSlice,
  files: filesSlice,
  goalsAndObjectives: goalsPageReducer,
  dashboardData: dashboardDataSlice,
  goalsObjectivesGroups: goalsObjectivesGroupsSlice,
  reports: reportsSlice,
  subjectsAdvanced: subjectsAdvancedSlice,
  common: commonSlice,
  teachersManagement: teachersManagementSlice,
  studentGroupsManagement: studentGroupsManagementSlice,
  subjectsManagement: subjectsManagementSlice,
  queryReports: queryReportsSlice,
  relativeSelectPickers: relativeSelectPickersSlice,
  emailTemplates: emailTemplatesSlice,
  digitalNotebook: digitalNotebookSlice,
  termDatesManagement: termDatesManagementSlice,
});

const initialState = {};
const middleware = [thunk];
let persistor;
let store;

const isProd = process.env.REACT_APP_ENV === 'production';

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

if (isProd) {
  const currentVersion = '0.9.932';
  const localStorageVersion = localStorage.getItem('version');
  if (currentVersion !== localStorageVersion) {
    localStorage.setItem('version', currentVersion);
    localStorage.removeItem('persist:root');
    localStorage.removeItem(USER_DATA);
  }
  const persistConfig = {
    key: 'root',
    storage,
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
  persistor = persistStore(store);
} else {
  store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
}

export default store;
export { persistor };
