import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { lateRegistrationsPageValues } from '../../consts/pages/lateRegistrations';
import axiosExt from '../utils/request';

const initialState = {
  page: 1,
  count: 3,
  lateRegistrationsListArray: [],
  lateRegistrationsListHashList: {},
  currentLateRegistrationsData: {},
  lateRegistrationsListIsLoading: false,
  activeFilters: {
    dateFrom: '',
    dateTo: '',
  },
};

export const actionFetchLateRegistrationsList = createAsyncThunk(
  'actionFetchLateRegistrationsList',
  async (requestData, thunkAPI) => {
    const state = thunkAPI.getState();
    const { page } = state.lateRegistrations;
    const response = await axiosExt({
      method: lateRegistrationsPageValues.getListAPIData.method,
      url: lateRegistrationsPageValues.getListAPIData.url,
      data: {
        Page: page,
        ...requestData,
      },
    });
    const {
      data: { Result: lateRegistrationsListArray },
    } = response;
    const lateRegistrationsListHashList = {};
    lateRegistrationsListArray.forEach(lateRegistration => {
      lateRegistrationsListHashList[lateRegistration.ID] = lateRegistration;
    });
    return { count: lateRegistrationsListArray?.length, lateRegistrationsListHashList, lateRegistrationsListArray };
  },
);

export const actionGetLateRegistrationsItem = createAsyncThunk('item/lateRegistrations', async ID => {
  const response = await axiosExt({
    method: 'POST',
    url: '',
    data: {
      ID,
    },
  });
  return response?.data?.Result || {};
});

export const actionCreateEditLateRegistrationsItem = createAsyncThunk(
  'item/lateRegistrations',
  async (data, { getState, rejectWithValue }) => {
    await axiosExt({
      method: 'POST',
      url: '',
      data,
      displaySuccessMessage: true,
    });
    return { ...getState().lateRegistrations.currentLateRegistrationsData, ...data };
  },
);

export const lateRegistrationsListSlice = createSlice({
  initialState,
  name: 'lateRegistrationsList',
  extraReducers: builder => {
    builder.addCase(actionFetchLateRegistrationsList.pending, state => {
      state.lateRegistrationsListIsLoading = true;
    });

    builder.addCase(actionFetchLateRegistrationsList.fulfilled, (state, action) => {
      const { count, lateRegistrationsListHashList, lateRegistrationsListArray } = action.payload;
      state.lateRegistrationsListIsLoading = false;
      state.count = count;
      state.lateRegistrationsListHashList = lateRegistrationsListHashList;
      state.lateRegistrationsListArray = lateRegistrationsListArray;
    });
    builder.addCase(actionFetchLateRegistrationsList.rejected, state => {
      state.lateRegistrationsListIsLoading = false;
    });
    builder.addMatcher(
      isAnyOf(actionGetLateRegistrationsItem.pending, actionCreateEditLateRegistrationsItem.pending),
      state => {
        state.lateRegistrationItemDataIsLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(actionGetLateRegistrationsItem.rejected, actionCreateEditLateRegistrationsItem.rejected),
      state => {
        state.lateRegistrationItemDataIsLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(actionGetLateRegistrationsItem.fulfilled, actionCreateEditLateRegistrationsItem.fulfilled),
      (state, action) => {
        state.lateRegistrationItemDataIsLoading = false;
        state.currentLateRegistrationsData = {
          ...state.currentLateRegistrationsData,
          ...action.payload,
          dataSnapshot: { ...state.currentLateRegistrationsData.dataSnapshot, ...action.payload },
        };
      },
    );
  },
  reducers: {
    resetLateRegistrationssPageState: () => initialState,
    resetCurrentLateRegistrationsData: state => {
      state.currentLateRegistrationsData = {};
    },
    changeCurrentLateRegistrationsData: (state, action) => {
      state.currentLateRegistrationsData = { ...state.currentLateRegistrationsData, ...action.payload };
    },
    changeActiveFilter: (state, action) => {
      state.activeFilters = { ...state.activeFilters, ...action.payload };
    },
  },
});

export default lateRegistrationsListSlice.reducer;

export const {
  changeCurrentLateRegistrationsData,
  resetLateRegistrationssPageState,
  resetCurrentLateRegistrationsData,
  changeActiveFilter,
} = lateRegistrationsListSlice.actions;
