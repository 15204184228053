import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';

import {
  CREATE_UPDATE_CANDIDATE_ITEM_API_URL,
  GET_CANDIDATE_ITEM_API_URL,
  GET_CANDIDATES_LIST_API_URL
} from '../../consts';
import axiosExt from '../utils/request';

const initialState = {
  page: 1,
  count: undefined,
  candidatesListArray: [],
  candidatesListHashList: {},
  currentCandidateData: {},
  candidatesListIsLoading: false,
  candidateItemDataIsLoading: false,
  activeFilters: {
    dateFrom: '',
    dateTo: '',
  },
};

export const actionFetchCandidatesList = createAsyncThunk('actionFetchCandidatesList', async (requestData, thunkAPI) => {
  const state = thunkAPI.getState();
  const { page } = state.candidates;
  const response = await axiosExt({
    method: 'POST',
    url: GET_CANDIDATES_LIST_API_URL,
    data: {
      Page: page,
      ...requestData,
    },
  });
  const { data: { Result: { RecordsTotal: count, Result: candidatesListArray = []} = {} = {}}} = response;
  const candidatesListHashList = {};
  candidatesListArray.forEach(candidate => {
    candidatesListHashList[candidate.ID] = candidate;
  })
  return { count, candidatesListHashList, candidatesListArray  };
});

export const actionGetCandidateItem = createAsyncThunk('item/candidates', async (ID) => {
  const response = await axiosExt({
    method: 'POST',
    url: GET_CANDIDATE_ITEM_API_URL,
    data: {
      ID,
    },
  });
  return response?.data?.Result || {};
});

export const actionCreateEditCandidateItem = createAsyncThunk('item/candidates', async (data, {getState, rejectWithValue }) => {
  await axiosExt({
    method: 'POST',
    url: CREATE_UPDATE_CANDIDATE_ITEM_API_URL,
    data,
    displaySuccessMessage: true,
  });
  return {...getState().candidates.currentCandidateData, ...data};
});

export const candidatesListSlice = createSlice({
  initialState,
  name: 'candidatesList',
  extraReducers: builder => {
    builder.addCase(actionFetchCandidatesList.pending, state => {
      state.candidatesListIsLoading = true;
    });

    builder.addCase(actionFetchCandidatesList.fulfilled, (state, action) => {
      const { count, candidatesListHashList, candidatesListArray  } = action.payload;
      state.candidatesListIsLoading = false;
      state.count = count;
      state.candidatesListHashList = candidatesListHashList;
      state.candidatesListArray = candidatesListArray;
    });
    builder.addCase(actionFetchCandidatesList.rejected, state => {
      state.candidatesListIsLoading = false;
    });
    builder.addMatcher(
      isAnyOf(actionGetCandidateItem.pending, actionCreateEditCandidateItem.pending),
      state => {
        state.candidateItemDataIsLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(actionGetCandidateItem.rejected, actionCreateEditCandidateItem.rejected),
      state => {
        state.candidateItemDataIsLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(actionGetCandidateItem.fulfilled, actionCreateEditCandidateItem.fulfilled),
      (state,action) => {
        state.candidateItemDataIsLoading = false;
        state.currentCandidateData = {...state.currentCandidateData,...action.payload, dataSnapshot: {...state.currentCandidateData.dataSnapshot, ...action.payload}};
      },
    );
  },
  reducers: {
    resetCandidatesPageState: () => initialState,
    resetCurrentCandidateData: state => {
      state.currentCandidateData = {};
    },
    changeCurrentCandidateData: (state, action) => {
      state.currentCandidateData = { ...state.currentCandidateData, ...action.payload };
    },
    changeActiveFilter: (state, action) => {
      state.activeFilters = { ...state.activeFilters, ...action.payload };
    },
  },
});

export default candidatesListSlice.reducer;

export const {
  changeCurrentCandidateData,
  resetCandidatesPageState,
  resetCurrentCandidateData,
  changeActiveFilter,
} = candidatesListSlice.actions;
